/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Calevent } from '../util/front_datatypes';
import { lang_suffix } from '../util/lang_util';
import { IMAGES_GENERAL_URL_PREFIX, INNERPAGE_PREFIX, timeFormat, dateFormat, GREEK_URL } from '../util/constants';
import { Link } from 'react-router-dom';
import Background from '../data/images/AboutBG.jpg';

import "../style/Calevents.scss"
import { hasPassed } from '../util/util';

interface CaleventsProps
{
    lang: string,
    calevents: Calevent[]
}

const Calevents = ({lang, calevents}: CaleventsProps) => {

    const langSuffix = lang_suffix(lang);
    const title = {
        "": "Συναυλίες & Εκδηλώσεις",
        "_en": "Concerts & Events"
    }

    const imageSuffix = {
        "": "_GR",
        "_en": "_EN"
    }

    const dateLocale = lang === GREEK_URL ? 'el-GR' : 'en-US';

    return (
        <div 
            id="tr-team"
            className="tr-team image-bg" 
            style={{backgroundImage: `url(${Background})`}}
        >
            <div className="overlay"/>
            <div style={{backgroundColor: `#000000dd`, zIndex: 0}} className="section-padding">

            <div className="container">
                <div className="section-info">
                    <h2>
                        {title[langSuffix]}
                    </h2>
                </div>

                <div className="team-slider">
                    {
                        calevents.map( (cal: Calevent, i: number) => {
                            const eventfrom = cal.eventfrom ? cal.eventfrom : "";
                            const description = cal[`description${langSuffix}`];

                            return (
                                <Link to={`${INNERPAGE_PREFIX}/${cal.id}/${lang}`} key={`calevent-${i}`}>
                                    <div className="team-member">
                                        {
                                            !hasPassed(eventfrom) ? 
                                            <img className="prosexos" src={`https://erp.maliaras.gr/images/apho/prosexos${imageSuffix[langSuffix]}.png`}/>
                                            : ""
                                        }
                                        <img className="img-responsive" src={`${IMAGES_GENERAL_URL_PREFIX}/${cal.image}`}/>
                                        <div className="team-overlay">
                                            <div className="member-info">
                                                <div>
                                                    {new Date(eventfrom).getFullYear() > 2015 ? 
                                                    (<p>{new Date(eventfrom).toLocaleTimeString(dateLocale, timeFormat)}<br/>{new Date(eventfrom).toLocaleDateString(dateLocale, dateFormat)}</p>) : ""}
                                                    <h4>{cal[`title${langSuffix}`]}</h4>
                                                    <p dangerouslySetInnerHTML={{__html: description !== null ? description : ""}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>
            </div>
            </div>
        </div>
    )
}

export default Calevents;