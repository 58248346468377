/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { pageType } from './Feed';
import { Innerpagedata, Video, Image, File } from '../util/innerpage_datatypes';
import innerpagedata_test_file from "../data/innerpage_test_clear.json";
import categorydata_test_file from "../data/category_test_clear.json";
import Axios from 'axios';
import { runGallery, setGallery, shutDownGallery, fixPosition, scrollToSelector } from '../util/ext';
import { lang_suffix, lang_url2num } from '../util/lang_util';
import "../style/Innerpage.scss";
import { IMAGES_GENERAL_URL_PREFIX, INNER_ENDPOINT, HEADER_HEIGHT, WEBSITE_ID, GREEK_URL, dateFormat, timeFormat, CATEGORY_ENDPOINT } from '../util/constants';
import Footer from './Footer';
import { defaultTitle } from '../util/useTitle';
import FastAverageColor from 'fast-average-color';
import Meta from './Meta';
import Loading from './Loading';
import Header from './Header';
import Ticketing from './Ticketing';
import { decodeHTML, extractVideoSource, hasPassed, videoLink } from '../util/util';
import ReactPlayer from 'react-player';
import { Categorydata } from '../util/category_datatypes';
import Breadcrumb from './Breadcrumb';
import InnerNavigator from './InnerNavigator';
import { Event } from '../util/front_datatypes';

interface InnerpageProps {

}

interface InnerpageParameters {
    lang: string
    id: string
}

const Innerpage = ({ location, match,  }: InnerpageProps & RouteComponentProps<InnerpageParameters>) => {
    const lang = match.params.lang ? match.params.lang : GREEK_URL;
    const id = match.params.id ? match.params.id : "0";

    const query = new URLSearchParams(location.search)
    const queryCategory: string | null = query.get('category');

    const [innerpagedata, setInnerpagedata] = useState<Innerpagedata>(innerpagedata_test_file);
    const [categorydata, setCategoryData] = useState<Categorydata>(categorydata_test_file);
    const [maxHeight, setMaxHeight] = useState<number | string>("100vh");
    const [backgroundColor, setBackgroundColor] = useState<string>("#fff");
    const [loading, setLoading] = useState<boolean>(true)

    const onImageLoad = () => {
        calculateHeight();
    }

    const calculateHeight = () => {
        const left = document.getElementsByClassName("allpage2")[0].clientHeight;

        const right = document.getElementsByClassName("allpage1")[0].clientHeight;

        const max = right > left ? right : left;
        fixPosition(".allpage2", max + HEADER_HEIGHT);

        setMaxHeight(max);
    }

    useEffect(() => {
        setGallery();

        return function unmount() {
            shutDownGallery();
        }
    }, [])

    useEffect(() => {
        const innerUrl = `${INNER_ENDPOINT}/${id}/${WEBSITE_ID}/${lang_url2num(lang)}`;

        Axios.get(innerUrl)
            .then(res => res.data)
            .then(res => {
                setInnerpagedata(res);
                setLoading(false);
                runGallery({ showdescription: 0 });
            })
    }, [id, lang])
    
    useEffect(() => {

        if (!innerpagedata) return;
        if (!innerpagedata.item.categories) return;

        
        const categories = innerpagedata.item.categories.split(',');
        const catid = queryCategory ? queryCategory : categories[0];

        const catUrl = `${CATEGORY_ENDPOINT}/${WEBSITE_ID}/${catid}/${lang_url2num(lang)}}`;

        Axios.get(catUrl)
            .then(res => res.data)
            .then(res => {
                setCategoryData(res);
            })
    }, [innerpagedata, lang, queryCategory])

    useEffect(() => {
        calculateHeight();
        window.scrollTo(0, 0)
    }, [innerpagedata])


    const langSuffix = lang_suffix(lang);
    const title = innerpagedata.item[`title${langSuffix}`];
    const description = innerpagedata.item[`description${langSuffix}`];
    let nextArticle: Event | null = null;
    let previousArticle: Event | null = null;
    
    for (let i = 0; i < categorydata.categoryarticles.length; i++)
    {
        if (categorydata.categoryarticles[i].id === innerpagedata.item.id)
        {
            nextArticle = i === categorydata.categoryarticles.length - 1 ? categorydata.categoryarticles[0] : categorydata.categoryarticles[i + 1];
            previousArticle = i === 0 ? categorydata.categoryarticles[categorydata.categoryarticles.length - 1] : categorydata.categoryarticles[i - 1];
            break;
        }
    }

    return (
        <div className="tr-details-page blog-details tr-page inner-page apho-page">
            <Meta
                lang={lang}
                title={title !== null ? title + " | " + defaultTitle(lang) : defaultTitle(lang)}
                description={description ? decodeHTML(description) : undefined}
                previewImage={innerpagedata.image && innerpagedata.image !== "" ?
                    `${IMAGES_GENERAL_URL_PREFIX}/${innerpagedata.image}`
                    :
                    (innerpagedata.image2.length > 0 ? `${IMAGES_GENERAL_URL_PREFIX}/${innerpagedata.image2[0]}` : undefined)}
                previewURL={window.location.href}
            />

            <Loading loading={loading} />
            <Header lang={lang} page={pageType.Innerpage} itemId={+id} />


            <div className="tr-main-wrapper" style={{ minHeight: maxHeight }}>
                <div className="blog-content">
                    <div className="container-fluid no-padding">
                        <div className="row">

                            <div className="col-sm-6 tr-sticky allpage2" style={{ height: "auto" }}>
                                <Sidebar
                                    innerpageData={innerpagedata}
                                    setBackground={setBackgroundColor}
                                    lang={lang}
                                    onImageLoad={() => { calculateHeight(); document.body.scrollTo(0, 0) }}
                                />
                            </div>

                            <div
                                className="dummy-left col-sm-6 tr-sticky"
                                style={{
                                    height: maxHeight,
                                    padding: 0,
                                    zIndex: -2,
                                    backgroundColor: backgroundColor,
                                    width: "50vw"
                                }} />

                            <div className="col-sm-6 tr-sticky allpage1" id="tester" style={{ paddingBottom: 100 }}>
                                <div className="navigation">
                                    <Breadcrumb
                                        categoryTitle={categorydata.catdata[`category${langSuffix}`]}
                                        categoryId={categorydata.categoryid}
                                        innerTitle={innerpagedata.item[`title${langSuffix}`]}
                                        lang={lang}
                                    />
                                    <br/>
                                    {nextArticle && previousArticle &&
                                        <InnerNavigator
                                            nextArticle={nextArticle}
                                            previousArticle={previousArticle}
                                            lang={lang}
                                        />
                                    }
                                </div>

                                <hr/>

                                <Maincon categoryData={categorydata} innerpageData={innerpagedata} lang={lang} onImageLoad={onImageLoad} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer lang={lang} />
        </div>
    )
}

const Sidebar = ({ innerpageData, setBackground, lang, onImageLoad }: { innerpageData: Innerpagedata, setBackground: Function, lang: string, onImageLoad: Function }) => {
    const langSuffix = lang_suffix(lang);
    
    const image = innerpageData.image && innerpageData.image !== "" ?
        `${IMAGES_GENERAL_URL_PREFIX}/${innerpageData.image}`
        :
        (innerpageData.image2.length > 0 ? `${IMAGES_GENERAL_URL_PREFIX}/${innerpageData.image2[0].image}` : "");

    let ImageElement: HTMLImageElement | null = document.createElement('img');

    useEffect(() => {

        if (!image || image === "") return;
        const fac = new FastAverageColor();

        if (ImageElement)
            ImageElement.crossOrigin = "Anonymous";

        fac.getColorAsync(ImageElement)
            .then((color: any) => {
                setBackground(color.rgba);
            })
            .catch((e: any) => {
                console.error(e);
            })
    }, [image, setBackground, ImageElement])

    return (
        <div className="theiaStickySidebar">
            <div className="tr-image">
                <div className="entry-header">
                    <div className="entry-thumbnail">
                        {
                            image !== "" ?
                                <img id="masterimng" className="img-responsive mainimg" src={image}
                                    alt={`${innerpageData.item[`title${langSuffix}`]} poster`} ref={b => ImageElement = b}
                                    onLoad={(e) => onImageLoad()}
                                />
                                :
                                null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const Maincon = ({ categoryData, innerpageData, lang, onImageLoad }: { categoryData: Categorydata, innerpageData: Innerpagedata, lang: string, onImageLoad: Function }) => {
    const langSuffix = lang_suffix(lang);

    const description = innerpageData.item[`descriptionfull${langSuffix}`] !== null ? innerpageData.item[`descriptionfull${langSuffix}`] : null;
    const streams = innerpageData.videos.filter(v => {
        if (innerpageData.item.eventto)
            return v.type === "Stream" && !hasPassed(innerpageData.item.eventto);
        else
            return v.type === "Stream";
    });
    const videos = innerpageData.videos.filter(v => {
        if (v.type === "Stream") {
            if (innerpageData.item.eventto) return hasPassed(innerpageData.item.eventto);
        }

        return v.type === "Video";
    });

    let imageNames: Set<string> = new Set<string>();
    let imageIds: Set<number> = new Set<number>();
    const dateLocale = lang === GREEK_URL ? 'el-GR' : 'en-US';

    const book = lang === GREEK_URL ? "Κάντε Κράτηση" : "Book Tickets";

    return (
        <div className="maincon">
            <div className="tr-right-content" style={{ padding: 0 }}>
                <div style={{ padding: "0px 30px" }}>
                    <div className="tr-post">
                        <div className="entry-meta">
                            {innerpageData.item.eventfrom !== null ?
                                <span className="time">
                                    {new Date(innerpageData.item.eventfrom).toLocaleDateString(dateLocale, dateFormat)}
                                    {", "}
                                    {new Date(innerpageData.item.eventfrom).toLocaleTimeString(dateLocale, timeFormat)}
                                    {
                                        innerpageData.item.eventto &&
                                        <>
                                            {" - "}
                                            {new Date(innerpageData.item.eventto).toLocaleDateString(dateLocale, dateFormat)}
                                            {", "}
                                            {new Date(innerpageData.item.eventto).toLocaleTimeString(dateLocale, timeFormat)}
                                        </>
                                    }
                                </span>
                                : null
                            }
                        </div>

                        <div className="entry-title-wrapper">
                            <div className="entry-title">
                                <h2>{innerpageData.item[`overtitle${langSuffix}`]}</h2>
                                <span className="time" style={{color: 'black'}}>{innerpageData.item[`title${langSuffix}`]}</span>
                            </div>

                            {innerpageData.item.ticketing !== "" && innerpageData.item.eventfrom && !hasPassed(innerpageData.item.eventfrom) &&
                                <>
                                    <button
                                        onClick={(event) => {
                                            event.preventDefault();
                                            scrollToSelector("#ticketing-anchor")
                                        }}
                                        className={`btn btn-primary ticketButton`}
                                    >
                                        {book}
                                    </button>
                                </>
                            }

                        </div>
                    </div>

                    {/* Stream */}
                    {streams && streams.length > 0 &&
                        <>
                            <h3>Streams</h3>
                            {streams.map(stream => {
                                return stream.embed ? <div dangerouslySetInnerHTML={{ __html: stream.embed }} />
                                    :
                                    stream.videoid &&
                                    <ReactPlayer
                                        controls
                                        width="100%"
                                        url={videoLink(stream.videoid, extractVideoSource(stream.source))}
                                    />
                            })
                            }
                            <hr />
                        </>
                    }

                    <div className="post-content" dangerouslySetInnerHTML={{ __html: description !== null ? description : "" }} />

                    {/* Attachments - Files */}
                    {innerpageData.files && innerpageData.files.length > 0 ?
                        <div style={{ listStyle: "none" }}>
                            <h3 style={{ marginBottom: 20 }}>{lang === GREEK_URL ? "Επισυνάψεις" : "Attachments"}</h3>
                            {
                                innerpageData.files.map((file: File, i) => {
                                    return (
                                        <li key={`attachment-${i}`}>
                                            <a target="_blank" rel="noopener noreferrer" href={`${IMAGES_GENERAL_URL_PREFIX}/${file.file}`}>
                                                {file.name}
                                            </a>
                                        </li>
                                    )
                                })
                            }
                        </div>
                        : null
                    }

                    <br />

                    {/* Tickets */}
                    {innerpageData.item.ticketing !== "" && innerpageData.item.eventfrom && !hasPassed(innerpageData.item.eventfrom) &&
                        <>
                            <h2 id="ticketing-anchor">{lang === GREEK_URL ? "Εισιτήρια - Κρατήσεις" : "Tickets - Bookings"}</h2>
                            <Ticketing ticketString={innerpageData.item.ticketing} eventFrom={innerpageData.item.eventfrom} lang={lang} />
                        </>
                    }

                    {/* Videos */}
                    {videos && videos.length > 0 ?
                        <>
                            <hr />
                            <h3>Videos</h3>
                            <div className="outervideo">
                                <div className="coverimg">
                                    {innerpageData.videos.map((video: Video, i) => {
                                        return video.embed ? <div key={`video-${i}`} dangerouslySetInnerHTML={{ __html: video.embed }} />
                                            :
                                            video.videoid &&
                                            <ReactPlayer
                                                key={`video-${i}`}
                                                controls
                                                width="100%"
                                                url={videoLink(video.videoid, extractVideoSource(video.source))}
                                            />
                                    })
                                    }
                                </div>
                            </div>
                        </>
                        : null
                    }

                    {/* Gallery Photos */}
                    {innerpageData.gallery.length > 0 && <h3>Gallery</h3>}
                    <div className="coverimg">

                        {innerpageData.gallery.map((image: Image, i) => {

                            if (!image.image || image.image === "" || imageNames.has(image.image) || imageIds.has(+image.id)) return null;
                            imageNames.add(image.image);
                            imageIds.add(+image.id);

                            return (
                                <a key={`gallery-image-${i}`} href="#">
                                    <img
                                        className="smallimg"
                                        src={`${IMAGES_GENERAL_URL_PREFIX}/${image.image}`}
                                        data-gallery="my-gallery" alt={`${innerpageData.item[`title${langSuffix}`]} gallery-${i}`}
                                        onLoad={() => onImageLoad()}
                                    />
                                </a>
                            )
                        })}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Innerpage;