import React from 'react';

import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import { hasPassed } from '../util/util';
import { MAIN_COLOR } from '../util/constants';


const styles = (theme: Theme) => createStyles({
	dialog: {
		height: "100%",
		width: "100%"
	},
	iframe: {
		position: "relative",
		outline: "none",
		border: "none",
		display: "block",
		width: "100%",
		height: 800,
		// 720
		'@media screen and (max-width: 1199px)': {
			height: 1300
		},
		// phones
		'@media screen and (max-width: 767px)': {
			height: 1300
		},
	},
	bar: {
		display: "flex",
		backgroundColor: `${MAIN_COLOR}`,
		color: "white"
	},
});

interface TicketingProps extends WithStyles<typeof styles>
{
	lang: string,
	eventFrom: string | null,
	ticketString: string,
}

const Ticketing = withStyles(styles)(({ lang, ticketString, eventFrom, classes }: TicketingProps) =>
{
	const condition = eventFrom && !hasPassed(eventFrom) && ticketString && ticketString !== "";

	return (
		condition ?
			<div className={classes.dialog}>
				<iframe
					id="ticketing-iframe"

					className={classes.iframe}
					srcDoc={viva(ticketString)}
					title="ticketing"
				/>
			</div>

	 	: null
	)
});

export const viva = (ticketing: string) => {
    const template = `
		<!DOCTYPE html>
		<html lang="en">

		<head>
			<meta charset="UTF-8">
			<meta name="viewport" content="width=device-width, initial-scale=1.0">
			<title>Ticketing</title>

			<style>
				body {
					height: 100%;
					width: 100%;
				}


				.eb-button--primary:not(.eb-button--disabled) {
					background-color: #73AEA1 !important;
				}

				.eb-button--primary:not(.eb-button--disabled):hover {
					background-color: #73AEA1;
				}
			</style>
		</head>

		<body>
			<div id="generic-ticketing">
				${ticketing}
			</div>
		</body>

		</html>`;

    return template;
}

export default Ticketing;

