import React from 'react';
import { LOGO_TRANSP, ENGLISH_URL, GREEK_URL } from '../util/constants';
import { links, SocialLink } from "./Feed";
import { Link } from 'react-router-dom';
import LOGO_TRANSP_EN from "../data/images/Logo_Transp_EN.png"

import "../style/Footer.scss";

interface FooterProps
{
    lang: string,
}

const Footer = ({lang}: FooterProps) => {

    const contactInfo = {
        [`${GREEK_URL}`]: [
            <a href="https://google.com/maps/search/?api=1&query=Πυθαγόρα+8+17455+Άλιμος" target="_blank" rel="noopener noreferrer">Πυθαγόρα 8 Άλιμος, 17455</a>,
            <a href="https://www.artemismaliara.gr/index.php/photos/photos" target="_blank" rel="noopener noreferrer">Χώρος τέχνης &amp; Πολιτισμού «Άρτεμις»</a>,
            "",
            "Γραμματεία",
            "Τζωρτζίνα Μερεντίτη",
            <a href="tel:+302109887028">+30 210-9887028 (10 πμ - 2 μμ)</a>
        ],
        [`${ENGLISH_URL}`]: [
            <a href="https://google.com/maps/search/?api=1&query=8+Pythagora+str+Alimos+17455" target="_blank" rel="noopener noreferrer">8 Pythagora str. Alimos, 17455</a>,
            <a href="https://www.artemismaliara.gr/index.php/photos/photos" target="_blank" rel="noopener noreferrer">«Artemis» Concert Hall</a>,
            "",
            "Secretariat",
            "Georgina Merentiti",
            <a href="tel:+302109887028">+30 210-9887028 (10 am - 2 pm)</a>
        ]
    }
    
    return (
        <div className="footer-section">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="footer-logo">
                            <br/>
                            <Link to={`/${lang}`}>
                                <img className="img-responsive" src={lang === GREEK_URL ? LOGO_TRANSP : LOGO_TRANSP_EN} alt="Philharmonia Logo"/>
                            </Link>
                        </div>

                        <div className="copyright">
                            <br/>
                            <p>{lang === GREEK_URL ? "2019 Φιλαρμόνια Ορχήστρα Αθηνών" : "2019 Athens Philharmonia Orchestra"}</p>
                        </div>
                    </div>

                    <div className="col-sm-4">
                        <div className="footer-widget">
                            <h3>{lang === GREEK_URL ? "Επικοινωνία" : "Contact Us"}</h3>
                            <ul className="tr-list">
                                {
                                    contactInfo[lang].map( (item: string | JSX.Element | HTMLAnchorElement, i: number) => {
                                        return <li key={`footer-contact-${i}`}>{item === "" ? <br/> : item}</li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>

                    <div className="col-sm-2">
                        <div className="footer-widget">
                            <h3>Social Network</h3>
                            <ul className="tr-list">
                                {links.map( (net: SocialLink, i: number) => {
                                    return <li key={`footer-social-${i}`}><a href={net.href} target={net.target}><i className={net.className}/>{" "}{net.name}</a></li>
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;