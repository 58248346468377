import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import axios from 'axios';
import Footer from './Footer';

import categorydata_test_file from '../data/category_test_clear.json';
import { Categorydata, Catdata } from '../util/category_datatypes';
import { IMAGES_CATEGORY_URL_PREFIX, IMAGES_GENERAL_URL_PREFIX, INNERPAGE_PREFIX, CATEGORY_PREFIX, CATEGORY_ENDPOINT, WEBSITE_ID, GREEK_URL, dateFormat, timeFormat } from '../util/constants';
import { Event } from '../util/front_datatypes';
import { Fade } from '@material-ui/core';

import "../style/Category.scss";
import { lang_suffix, lang_url2num } from '../util/lang_util';
import useTitle, { defaultTitle } from '../util/useTitle';
import { pageType } from './Feed';
import { Link } from 'react-router-dom';
import Loading from './Loading';
import Header from './Header';
import Meta from './Meta';
import Breadcrumb from './Breadcrumb';
import { useQuery } from '../util/util';

interface CategoryProps {

}

interface CategoryParameters {
    lang: string
    id: string
}

const Category = ({ match }: CategoryProps & RouteComponentProps<CategoryParameters>) => {
    const lang = match.params.lang ? match.params.lang : GREEK_URL;
    const id = match.params.id ? match.params.id : "0";

    const [categorydata, setCategorydata] = useState<Categorydata>(categorydata_test_file);
    const [mastercategorydata, setMasterCategoryData] = useState<Categorydata>(categorydata_test_file);
    const [maxHeight, setMaxHeight] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    const query = useQuery();
    const queryCategory = query.get('category');

    useEffect(() => {
        const url = `${CATEGORY_ENDPOINT}/${WEBSITE_ID}/${id}/${lang_url2num(lang)}`;

        axios.get(url)
            .then(res => res.data)
            .then(res => {
                setCategorydata(res);
                setLoading(false);
            })

            window.scrollTo(0, 0)
    }, [id, lang])

    useEffect(() => {
        const catid = queryCategory ? queryCategory : categorydata.catdata.subcategory;
        const url = `${CATEGORY_ENDPOINT}/${WEBSITE_ID}/${catid}/${lang_url2num(lang)}`;

        if (!catid || catid === '') return;

        axios.get(url)
            .then(res => res.data)
            .then(res => {
                setMasterCategoryData(res);
            })
    }, [categorydata, lang, queryCategory])

    useEffect(() => {
        const left = document.getElementsByClassName("category-left")[0].clientHeight;
        const right = document.getElementsByClassName("category-right")[0].clientHeight;

        setMaxHeight(right > left ? right : left);
    }, [categorydata])

    const langSuffix = lang_suffix(lang);
    const title = categorydata.catdata[`category${langSuffix}`];
    useTitle(title !== null ? title + " | " + defaultTitle(lang) : defaultTitle(lang));

    return (
        <div className="tr-page category-page apho-page">

            <Meta
                lang={lang}
                title={title !== null ? title + " | " + defaultTitle(lang) : defaultTitle(lang)}
                previewURL={window.location.href}
            />

            <Header lang={lang} page={pageType.Category} itemId={+id} transparent inverse/>

            <Loading loading={loading}/>

            <div className="tr-main-wrapper" >
                <div className="container">
                    <div className="row">
                        <Sidebar categoryData={categorydata} lang={lang} height={maxHeight} masterCategoryData={mastercategorydata} />
                        <Mscroller lang={lang} categoryData={categorydata} height={maxHeight} />
                    </div>
                </div>
            </div>

            <Footer lang={lang} />
        </div>
    )
}

const Sidebar = ({ lang, categoryData, masterCategoryData, height }: { lang: string, categoryData: Categorydata, masterCategoryData: Categorydata, height: number }) => {

    const compareSubcategories = (a: Catdata, b: Catdata) => {
        if (!a.eventfrom) return 1;
        if (!b.eventfrom) return -1;
        if (new Date(a.eventfrom) > new Date(b.eventfrom)) return -1;
        else return 1;
    }

    const description = categoryData.catdata.description ? categoryData.catdata.description : "";
    const langSuffix = lang_suffix(lang);

    return (
        <div className="col-sm-4 col-md-3 tr-sticky category-left tr-before" style={{ minHeight: height }}>
            <div className="theiaStickySidebar">
                <div className="tr-left-contant">


                    <div className="page-title">
                        {masterCategoryData && masterCategoryData.catdata && <Breadcrumb
                            style={{textAlign: 'center'}}
                            categoryTitle={masterCategoryData.catdata[`category${langSuffix}`]}
                            categoryId={masterCategoryData.categoryid}
                            innerTitle={categoryData.catdata[`category${langSuffix}`]}
                            lang={lang}
                        />}
                        <br/>
                        <h1>{categoryData.catdata[`category${langSuffix}`]}</h1>
                    </div>

                    <h2 style={{ textAlign: "center" }} dangerouslySetInnerHTML={{ __html: description }}></h2>

                    <ul className="nav nav-tabs tr-list-menu" role="tablist">
                        {
                            categoryData.subcategories.sort(compareSubcategories).map((sub, i) => {
                                return (
                                    <li key={`subcategory-${i}`}>
                                        <Link to={`${CATEGORY_PREFIX}/${sub.id}/${lang}?category=${categoryData.categoryid}`}>
                                            {sub[`category${langSuffix}`]}
                                            {/* <i className="fas fa-angle-right"></i> */}
                                        </Link>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

const Mscroller = ({ categoryData, lang, height }: { categoryData: Categorydata, lang: string, height: number }) => {
    const langSuffix = lang_suffix(lang);

    const call2action = {
        "": "Περισσότερα",
        "_en": "more"
    }

    const dateLocale = lang === GREEK_URL ? 'el-GR' : 'en-US';

    return (
        <div id="mscroller" className="col-sm-8 col-md-9 tr-sticky category-right">
            <div className="right-content theiaStickySidebar" style={{ marginTop: 0 }}>
                <div className="tab-content">
                    <div className="tab-pane active fade in" id="print-media" role="tabpanel">
                        {
                            categoryData.mainimages.length !== 0 ?
                                <img
                                    alt={`${categoryData.catdata[`category${langSuffix}`]} category banner`}
                                    src={`${IMAGES_CATEGORY_URL_PREFIX}/${categoryData.mainimages[0].image}`}
                                    style={{ objectFit: "cover", minHeight: height }}
                                    className="mastertop tt2"
                                />
                                : ""
                        }
                        {
                            categoryData.mainimages.length === 0 ?
                                categoryData.categoryarticles.map((art: Event, i) => {

                                    if (categoryData.categoryid === 75) // ΠΡΟΣΕΧΩΣ
                                    {
                                        if (art.eventto && new Date(art.eventto) < new Date(Date.now()))
                                            return null
                                    }

                                    const description = art[`description${langSuffix}`];
                                    const innerLink = `${INNERPAGE_PREFIX}/${art.id}/${lang}?category=${categoryData.categoryid}`;

                                    return (
                                        <Fade in={true} key={`article-${i}`} >
                                            <Link to={innerLink} >
                                                <div className="tr-post row" style={{ margin: 0 }}>
                                                    <div className="col-md-5">
                                                        <div className="entry-header">
                                                            {art.image ?
                                                                <div className="entry-thumbnail">
                                                                    <img className="img-responsive" src={`${IMAGES_GENERAL_URL_PREFIX}/${art.image}`} 
                                                                    alt={`${art[`title${langSuffix}`]} article`} />
                                                                </div> : null}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-7">
                                                        <div className="post-content">
                                                            <div className="entry-meta">
                                                                {art.eventfrom ?
                                                                    <span className="time">
                                                                        {new Date(art.eventfrom).toLocaleDateString(dateLocale, dateFormat) + ", " + new Date(art.eventfrom).toLocaleTimeString(dateLocale, timeFormat)}
                                                                    </span>
                                                                    : ""}
                                                            </div>

                                                            <div className="entry-title">
                                                                {art[`overtitle${langSuffix}`]}
                                                                <h2>
                                                                    {art[`title${langSuffix}`]}
                                                                </h2>
                                                            </div>

                                                            <div dangerouslySetInnerHTML={{ __html: description !== null ? description : "" }} />
                                                            <p className="btn btn-default">
                                                                {call2action[langSuffix]}
                                                                <i className="fas fa-chevron-right" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Fade>

                                    )
                                }) : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Category;