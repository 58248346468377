/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import { RouteComponentProps } from 'react-router';
import Footer from './Footer';
import Axios from 'axios';
import Gallerydata, { Image } from '../util/gallery_datatypes';
import { runGallery, setGallery, shutDownGallery } from '../util/ext';
import { IMAGES_GALLERY_URL_PREFIX, GALLERY_ENDPOINT, GREEK_URL, GALLERY_ID } from '../util/constants';
import { lang_suffix } from '../util/lang_util';

import "../style/Gallery.scss"
import useTitle, { defaultTitle } from '../util/useTitle';
import { Link } from 'react-router-dom';
import { pageType } from './Feed';
import Loading from './Loading';
import Header from './Header';

interface GalleryProps {

}

interface GalleryParameters {
    lang: string
    id: string
}

const Gallery = ({ match }: GalleryProps & RouteComponentProps<GalleryParameters>) => {
    const lang = match.params.lang ? match.params.lang : "1";

    const [galleryData, setGalleryData] = useState<Gallerydata>({ gallery: [] })
    const [loading, setLoading] = useState<boolean>(true);

    const loaded_images = useRef<boolean[]>([]);

    useTitle(`Photo Gallery | ${defaultTitle(lang)}`)

    useEffect(() => {
        setGallery();

        return function unmount() {
            shutDownGallery();
        }
    }, [])

    useEffect(() => {
        const url = `${GALLERY_ENDPOINT}/${GALLERY_ID}/${lang}`;

        Axios.get(url)
            .then(res => res.data)
            .then(res => {
                setGalleryData(res);
                loaded_images.current = res.gallery.map((i: any) => false);

                runGallery({showdescription: 1});
            })

    }, [lang])

    /** callback that is called onLoad for each image
     * When all images have loaded, remove loading screen
     */
    const image_has_loaded = (index: number) => {
        loaded_images.current[index] = true;

        if (loaded_images.current.filter( r => r === false).length === 0)
            setLoading(false);
    }

    const langSuffix = lang_suffix(lang);

    return (
        <div className="gallery-page">
            <Loading loading={loading}/>

            <Header lang={lang} page={pageType.Gallery} transparent/>

            <div className="tr-main-wrapper">
                <div className="blog-content">
                    <div className="container-fluid-no-padding">
                        <div className="row">
                            <div id="gallery">
                                <div className="coverimg">
                                    <h1>{lang === GREEK_URL ? "Στιγμές " : "Moments "} - Gallery</h1>
                                    {
                                        galleryData.gallery.map((img: Image, i) => {
                                            
                                            const words = img[`text${langSuffix}`] ? img[`text${langSuffix}`].split(' ') : [];
                                            const link = words.length > 0 && words[words.length - 1].includes('http') ? words[words.length - 1] : null;

                                            const more = lang === GREEK_URL ? "Περισσότερα..." : "Read More..."
                                            
                                            return (
                                                <span key={`gallery-img-${i}`}>
                                                    <a href="#" style={{height: 100}}>
                                                        <img
                                                            alt={`gallery-${i}`} 
                                                            data-id={img.id}
                                                            data-index={i}
                                                            className="smallimg"
                                                            src={`${IMAGES_GALLERY_URL_PREFIX}/${img.file}`}
                                                            data-gallery="my-gallery"
                                                            onLoad={() => image_has_loaded(i)}
                                                        />
                                                    </a>
                                                    <div id={`imgdesc_${i}`} style={{ display: "none" }}>
                                                        {
                                                            words.map( (word: string, j) => {
                                                                if (link && j === words.length - 1) return null;

                                                                return (
                                                                    <span key={`word-${j}`} style={{color: "#fff", textDecoration: "none", textAlign: "center"}}>
                                                                        {word + " "}
                                                                    </span>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            link ?
                                                            <Link to={new URL(link.substring(link.indexOf("http"))).pathname}><p style={{transition: "200ms"}}>{more}</p></Link>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </span>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer lang={lang} />
        </div>
    )
}

export default Gallery;