import React from 'react';

import { withStyles, WithStyles, createStyles, Theme, Button } from '@material-ui/core';
import { Event } from '../util/front_datatypes';
import { lang_suffix } from '../util/lang_util';
import { Link } from 'react-router-dom';
import { INNERPAGE_PREFIX } from '../util/constants';
import { useLocation } from 'react-router';

const styles = (theme: Theme) => createStyles({
	root: {
		position: 'relative',
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between'
	},
	button: {
		// fontSize: '16px !important',
		background: 'none',
		transition: '200ms',
		'&:hover': {
			backgroundColor: 'inherit'
		},
		'&:active': {
			background: 'none',
			boxShadow: 'none',
			
		}
	},
	previous: {
		'&:hover': {
			'& $previousIcon': {
				marginRight: 10,
			}
		}
	},
	next: {

	},
	previousIcon: {
		marginRight: 5,
		marginLeft: '0 !important',
	}
});

interface InnerNavigatorProps extends WithStyles<typeof styles>
{
	nextArticle: Event,
	previousArticle: Event,
	lang: string
}


const InnerNavigator = withStyles(styles)(({ classes, nextArticle, previousArticle, lang }: InnerNavigatorProps) =>
{
	const langSuffix = lang_suffix(lang);
	const location = useLocation();

	return (
		<div className={classes.root}>
			<Button
				component={Link}
				className={`btn btn-default ${classes.button} ${classes.previous}`}
				to={`${INNERPAGE_PREFIX}/${previousArticle.id}/${lang}${location.search}`}
				disableRipple
			>
				<i className={`fas fa-chevron-left ${classes.previousIcon}`} />
				{previousArticle[`title${langSuffix}`]}
			</Button>
			<Button
				component={Link}
				className={`btn btn-default ${classes.button} ${classes.next}`}
				to={`${INNERPAGE_PREFIX}/${nextArticle.id}/${lang}${location.search}`}
				disableRipple
			>
				{nextArticle[`title${langSuffix}`]}
				<i className="fas fa-chevron-right" />
			</Button>
		</div>
	)
});

export default InnerNavigator;

