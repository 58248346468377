/* eslint-disable no-unused-vars */

import { isAbsolute } from "path";

/* eslint-disable no-undef */
export const runAll = () => {
    preloader();
    portfolioSlider();
    teamSlider();
    cubeportfolio();
    onePageNav();
    magnificPopup();
    animationEnd();

}

export const slideHeight = () => {
    jQuery(function ($) {
        (function () {
            var height = $(window).height();
            $(".slide-content").innerHeight(height);
        }());
    })
}

export const portfolioSlider = () => {
    jQuery(function ($) {
        $(".portfolio-slider").not('slick-initialized').slick({
            infinite: true,
            slidesToShow: 4,
            autoplay: true,
            autoplaySpeed: 10000,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });
    })
}

export const teamSlider = () => {
    jQuery(function ($) {
        $(".team-slider").not('.slick-initialized').slick({
            infinite: true,
            slidesToShow: 4,
            autoplay: false,
            autoplaySpeed: 10000,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 481,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });
    })
}

export const cubeportfolio = () => {
    jQuery(function ($) {
        $('#portfolio-item').cubeportfolio({
            filters: '#portfolio-menu',
            loadMore: '#portfolio-menu',
            loadMoreAction: 'click',
            layoutMode: 'grid',
            defaultFilter: '*',
            animationType: 'quicksand',
            gapHorizontal: 35,
            gapVertical: 30,
            gridAdjustment: 'responsive',
            mediaQueries: [{
                width: 800,
                cols: 3
            }, {
                width: 470,
                cols: 2
            }, {
                width: 0,
                cols: 1
            }],

        });
    })
}

export const onePageNav = () => {
    jQuery(function ($) {
        $(function () {
            $('.tr-menu ul').onePageNav();
        });
    })
}


export const magnificPopup = () => {
    jQuery(function ($) {
        (function () {
            $('.video-link').magnificPopup({ type: 'iframe' });
        }());
    })
}

export const theiaStickySidebar = () => {
    jQuery(function ($) {
        (function () {

            $('.tr-sticky')
                .theiaStickySidebar({
                    additionalMarginTop: 0
                });
        }());
    })
}

export const animationEnd = () => {
    jQuery(function ($) {
        (function ($) {

            //Function to animate slider captions 
            function doAnimations(elems) {
                //Cache the animationend event in a variable
                var animEndEv = 'webkitAnimationEnd animationend';

                elems.each(function () {
                    var $this = $(this),
                        $animationType = $this.data('animation');
                    $this.addClass($animationType).one(animEndEv, function () {
                        $this.removeClass($animationType);
                    });
                });
            }

            //Variables on page load 
            var $myCarousel = $('#home-carousel'),
                $firstAnimatingElems = $myCarousel.find('.item:first').find("[data-animation ^= 'animated']");

            //Initialize carousel 
            $myCarousel.carousel();

            //Animate captions in first slide on page load 
            doAnimations($firstAnimatingElems);

            //Pause carousel  
            $myCarousel.carousel('pause');

            //Other slides to be animated on carousel slide event 
            $myCarousel.on('slide.bs.carousel', function (e) {
                var $animatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
                doAnimations($animatingElems);
            });

        })(jQuery);
    })
}

export const setGallery = () => {

    (function ($) {
        $.fn.setcontent = function (id) {
            var html = $('#imgdesc_' + id).html();

            var leftOrRight = +id % 2 === 0 ? "left" : "right";

            $("#ddssc").removeClass("right");
            $("#ddssc").removeClass("left");
            $("#ddssc").addClass(leftOrRight)

            $('#ddssc').html(html);
        }

        $.fn.jquerygallery = function (options) {
            var defauts =
            {
                'coverImgOverlay': true,
                'imgActive': "imgActive",
                'thumbnail': "coverImgOverlay",
                'thumbnailHeight': 120,
                'overlay': "overlay",
                'description': "ddssc",
                'imgNext': "<i class='fa fa-angle-right'></i>",
                'imgPrev': "<i class='fa fa-angle-left'></i>",
                'imgClose': "<i class='fa fa-times'></i>",
                'speed': 300,
                'showdescription': 0
            };
            var settings = $.extend(defauts, options);
            return this.each(function () {

                var imgActiveClass = "." + settings.imgActive,
                    thumbnailClass = "." + settings.thumbnail,
                    overlayClass = "." + settings.overlay,
                    descriptionClass = "." + settings.description;

                $("body").off("click", "img[data-gallery]");
                $("body").on("click", "img[data-gallery]", function () {

                    var link = $(this).attr("src"),
                        dataGallery = $(this).data("gallery"),
                        dataGalleryLength = $("img[data-gallery=\"" + dataGallery + "\"]").length;

                    $("<div class='" + settings.overlay + "'></div>").prependTo("body").fadeIn(100);

                    $("<div class='" + settings.imgActive + "'></div>").prependTo(overlayClass).fadeIn(settings.speed);
                    if (settings.showdescription === 1) {
                        $("<div id='" + settings.description + "' class='" + settings.description + "'></div>").appendTo(overlayClass).fadeIn(settings.speed);
                    }

                    if (settings.coverImgOverlay) { $("<div class='" + settings.thumbnail + "'></div>").appendTo(overlayClass).fadeIn(settings.speed); }

                    $("<div class='prev' tabindex='3'>" + settings.imgPrev + "</div><div class='next' tabindex='1'>" + settings.imgNext + "</div><div class='close' tabindex='2'>" + settings.imgClose + "</div>").appendTo(overlayClass);

                    //create every pictures on the related gallery and append them to main class or the thumbnails.
                    $("img[data-gallery=\"" + dataGallery + "\"]").each(function (i) {
                        var imgIndex = $(this).attr("data-index");

                        var leftOrRight = settings.showdescription ? (imgIndex % 2 === 0 ? "left" : "right") : "";

                        var dataGallery = $(this).data("gallery");
                        var imgLink = $(this).attr("src");
                        if (settings.showdescription === 1) {
                            var imgID = $(this).attr("data-id");
                            $(imgActiveClass).append("<img data-id='" + imgID + "' data-index='" + imgIndex + "' class='glvb " + leftOrRight + "' src='" + imgLink + "' data-gallery='" + dataGallery + "' data-number='" + (i + 1) + "'/>");
                            if (settings.coverImgOverlay) { $("<span data-id='" + imgID + "' data-index='" + imgIndex + "' data-thumbnail='yes'><img data-id='" + imgID + "' data-index='" + imgIndex + "' src='" + imgLink + "' data-gallery='" + dataGallery + "' data-number='" + (i + 1) + "'/></span>").appendTo(thumbnailClass).hide().fadeIn(settings.speed); }
                        } else {
                            $(imgActiveClass).append("<img class='glvb " + leftOrRight + "' src='" + imgLink + "' data-gallery='" + dataGallery + "' data-number='" + (i + 1) + "'/>");
                            if (settings.coverImgOverlay) { $("<span data-thumbnail='yes'><img src='" + imgLink + "' data-gallery='" + dataGallery + "' data-number='" + (i + 1) + "'/></span>").appendTo(thumbnailClass).hide().fadeIn(settings.speed); }
                        }
                    });

                    if (settings.coverImgOverlay) {
                        $(imgActiveClass).find("img").css("border-bottom", settings.thumbnailHeight + "px solid transparent");
                    }

                    //Actions when sliding
                    var slide = {
                        next: function () {
                            var widthImg = $(imgActiveClass).find("img:first-child").width(),
                                nextP = $(imgActiveClass).find("img:first-child").next();
                            $(imgActiveClass).find("img:first-child").stop().animate({ "margin-left": "-10%", "opacity": 0 }, settings.speed, function () {
                                $(imgActiveClass).find("img:first-child").hide();
                                $(this).css({ "margin-left": 0, "opacity": 1 }).appendTo(imgActiveClass);
                                nextP.fadeIn(settings.speed);
                                slide.isActive();
                            });
                        },
                        prev: function () {
                            var widthImg = $(imgActiveClass).find("img:first-child").width();
                            $(imgActiveClass).find("img:first-child").stop().animate({ "margin-left": "10%", "opacity": 0 }, settings.speed, function () {
                                $(imgActiveClass).find("img:first-child").hide();
                                $(this).css({ "margin-left": 0, "opacity": 1 });
                                $(imgActiveClass).find("img:last-child").prependTo(imgActiveClass).fadeIn(settings.speed);
                                slide.isActive();
                            });
                        },
                        //hide every pictures except the one that has been clicked.
                        showImgActive: function () {
                            $(imgActiveClass + " img").hide();
                            $(imgActiveClass).find("img[src='" + link + "']").nextAll().prependTo(imgActiveClass);
                            $(imgActiveClass).find("img[src='" + link + "']").prependTo(imgActiveClass).fadeIn(settings.speed);

                            var thisimgid = $(imgActiveClass).find("img[src='" + link + "']").attr('data-id');
                        },
                        //highlight the related thumbnail picture.
                        isActive: function () {
                            $(thumbnailClass + " img").css("opacity", 0.2);
                            $(thumbnailClass + " img").each(function () {
                                var activeSrc = $(imgActiveClass + " img:first-child").attr("src");
                                if ($(this).attr("src") === activeSrc) {


                                    if (settings.showdescription === 1) {
                                        var thisimgindex = $(this).attr('data-index');
                                        $.fn.setcontent(thisimgindex);
                                    }

                                    var width = $(this).width() / 2;
                                    var offleft = $(this).offset().left;
                                    var offsetLeft = $(thumbnailClass).offset().left;
                                    var marginLeft = offsetLeft - offleft;
                                    $(thumbnailClass).animate({ "margin-left": marginLeft - width }, settings.speed);
                                    $(this).css("opacity", 1);
                                }
                            });
                        },
                        shutDown: function () {
                            $(overlayClass).fadeOut(function () { $(overlayClass).remove(); });
                        }
                    };

                    //init slide
                    slide.showImgActive();
                    slide.isActive();

                    if (dataGalleryLength < 2) {
                        $(".prev,.next," + thumbnailClass).remove();
                        $(imgActiveClass).find("img").css("padding-bottom", 0);
                    }

                    if (dataGalleryLength > 1) {
                        //Slide on click
                        $(overlayClass).on("click", ".prev,.next," + imgActiveClass + ", span[data-thumbnail='yes']", function (index) {

                            if ($(this).hasClass("prev")) { slide.prev(); return false; }
                            if ($(this).hasClass("next") || $(this).hasClass(settings.imgActive)) { slide.next(); return false; }
                            if ($(this).attr("data-thumbnail") === "yes") {


                                if (settings.showdescription === 1) {
                                    var thisimgindex = $(this).attr('data-index');
                                    $.fn.setcontent(thisimgindex);
                                }

                                var sliderNumber = $(this).index() + 1,
                                    current = $(imgActiveClass + " img:first-child").data("number"),
                                    next = $(this).find("img").data("number"),
                                    widthImg = $(imgActiveClass + " img:first-child").width(),
                                    nextP = $(this).find("img").attr("src"),
                                    offleft = $(this).offset().left,
                                    offsetLeft = $(thumbnailClass).offset().left,
                                    marginLeft = offsetLeft - offleft,
                                    width = $(this).width() / 2;

                                $(thumbnailClass + " img").css("opacity", 0.2);
                                $(this).find("img").css("opacity", 1);

                                if (sliderNumber !== current) {
                                    $(thumbnailClass).animate({ "margin-left": marginLeft - width }, settings.speed);
                                    if (sliderNumber > current) {
                                        $(imgActiveClass).find("img:first-child").stop().animate({ "margin-left": "-10%", "opacity": 0 }, settings.speed, function () {
                                            $(imgActiveClass + " img:first-child").hide();
                                            $(imgActiveClass + " img:first-child").appendTo(imgActiveClass);
                                            $(this).css({ "margin-left": 0, "opacity": 1 });
                                            $(imgActiveClass + " img[src='" + nextP + "']").nextAll().prependTo(imgActiveClass);
                                            $(imgActiveClass + " img[src='" + nextP + "']").prependTo(imgActiveClass).fadeIn(settings.speed);
                                        });
                                    }
                                    if (sliderNumber < current) {
                                        $(imgActiveClass).find("img:first-child").stop().animate({ "margin-left": "10%", "opacity": 0 }, settings.speed, function () {
                                            $(imgActiveClass + " img:first-child").hide();
                                            $(imgActiveClass + " img:first-child").appendTo(imgActiveClass);
                                            $(this).css({ "margin-left": 0, "opacity": 1 });
                                            $(imgActiveClass + " img[src='" + nextP + "']").nextAll().prependTo(imgActiveClass);
                                            $(imgActiveClass + " img[src='" + nextP + "']").prependTo(imgActiveClass).fadeIn(settings.speed);
                                        });
                                    }
                                }
                                return false;
                            }
                        });
                    }
                    //Slide on keyboard
                    $(document).keydown(function (event) {
                        if (event.which === 39 || event.which === 38) { slide.next(); }
                        if (event.which === 40 || event.which === 37) { slide.prev(); }
                        if (event.which === 27) { slide.shutDown(); }
                    });

                    $.fn.shutDownSlide = () => {
                        slide.shutDown();
                    }

                    return false;
                });

                $("body").on("click", overlayClass, function () {
                    $(this).fadeOut(function () { $(this).remove(); });
                });
            });
        };
    })(jQuery);
}

export const shutDownGallery = () => {
    if ($(document).shutDownSlide) $(document).shutDownSlide();
}

export const runGallery = (options) => {
    $(document).jquerygallery(options);
}

export const preloader = () => {
    $(window).load(function () {
        $('#preloader').fadeOut('slow', function () { $(this).remove(); });
    });
}

/** Fixes the position of the element until a certain point. 
 * Works with items, which their pivot is at the top left
 * The "certain point" is tested against the bottom part of the element which is scrollTop() + elementHeight
*/
export const fixPosition = (selector, pos) => {
    const element = $(selector);

    const elementHeight = element.outerHeight();

    const setPosition = () => {

        if ($(window).width() < 767) {
            element.css({
                position: "unset",
            })

            return;
        }

        if ($(window).scrollTop() + element.outerHeight() > pos) {
            let top = pos - element.outerHeight();

            element.css({
                position: 'absolute',
                top: top
            })
        }
        else {
            element.css({
                position: "fixed",
                top: 0
            })
        }
    }

    setPosition();

    $(window).off('resize');
    $(window).resize((e) => {
        setPosition();
    })

    $(window).off('scroll');
    $(window).scroll((e) => {
        setPosition();
    })
}

export const findZeroScroll = () => {
    return $(window).scrollTop() === 0;
}

export const isInViewport = (element) => {
    if (!element) return;

    var rect = element.getBoundingClientRect();

    return (
        rect.top > 0 &&
        rect.left >= 0 &&
        // rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
}


export const scrollToSelector = (selector) => {
    $.scrollTo($(selector), 0, {offset: {left: 0, top: -100}});
}

window.scrollToSelector = scrollToSelector;