// Language
export const GREEK = "1";
export const ENGLISH = "2";
export const GREEK_URL = "gr";
export const ENGLISH_URL = "en";

// Config
export const WEBSITE_ID = "2";
export const GALLERY_ID = "0";

// Website URL
export const INNERPAGE_PREFIX = "/innerpage";
export const CATEGORY_PREFIX = "/category";
export const FRONTPAGE_PREFIX = "/home"
export const GALLERY_PREFIX = "/gallery"
export const SEARCH_PREFIX = "/search"

// Images
export const IMAGES_GENERAL_URL_PREFIX = "https://erp.maliaras.gr/images/content";
export const IMAGES_CATEGORY_URL_PREFIX = "https://erp.maliaras.gr/images/categories";
export const IMAGES_GALLERY_URL_PREFIX = "https://erp.maliaras.gr/images/apho/gallery";
export const LOGO_TRANSP = "https://erp.maliaras.gr/images/apho/Orchestra_Logo_White_Transp.png";
export const LOGO_TRANSP_EN = "";
export const LOGO = "https://erp.maliaras.gr/images/apho/orchestra.png";

// REST data endpoints
export const FRONT_ENDPOINT = "https://erp.maliaras.gr/website/apho_frontpage";
export const CATEGORY_ENDPOINT = "https://erp.maliaras.gr/website/categorydata";
export const INNER_ENDPOINT = "https://erp.maliaras.gr/website/innerdata";
export const GALLERY_ENDPOINT = "https://erp.maliaras.gr/website/apho_gallery_data";
export const NEWSLETTER_ENDPOINT = "https://erp.maliaras.gr/website/ctsubemail";
export const CONTACT_ENDPOINT = "https://erp.maliaras.gr/website/sendmail";
export const INNER_IDS_ENDPOINT = "https://erp.maliaras.gr/website/inner_ids";
export const CATEGORY_IDS_ENDPOINT = "https://erp.maliaras.gr/website/category_ids";
export const GET_TAGS_ENDPOINT = "https://erp.maliaras.gr/website/get_tags";
export const SEARCH_ENDPOINT = "https://erp.maliaras.gr/website/apho_search";

// Some Styling
export const HEADER_HEIGHT = 64;
export const MAIN_COLOR = "#73aea1"
export const MAIN_COLOR_VIBRANT = "#41b199";

// Date - Time string
export const dateFormat: any = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
}

export const timeFormat: any = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
}