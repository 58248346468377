import React, { useState, useEffect } from 'react';

import { withStyles, WithStyles, createStyles, Theme, AppBar, Toolbar, IconButton, useTheme, useMediaQuery, Box, Menu, MenuItem, Paper, Fade } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu'
import { CATEGORY_PREFIX, INNERPAGE_PREFIX, FRONTPAGE_PREFIX, GREEK_URL, GALLERY_PREFIX, HEADER_HEIGHT, MAIN_COLOR, MAIN_COLOR_VIBRANT } from '../util/constants';
import Logo from '../data/images/Orchestra_Logo_White-01.png';
import DarkLogo from '../data/images/Orchestra_Logo_black-01.png';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { hover, active } from '../style/Theme';
import Feed, { pageType } from "./Feed";
import { defaultTitle } from '../util/useTitle';
import { findZeroScroll } from '../util/ext';
import Popup from 'reactjs-popup';
import "../style/Header.scss"
import SearchForm from './SearchForm';

const black = "#1a1a1a";

const styles = (theme: Theme) => createStyles({
	root: {
		fontFamily: "unset",
		transition: "500ms"
	},
	toolbar: {
		display: "flex",
		transition: "500ms"
	},
	menu: {
		flexGrow: 1,
		width: "unset",
		height: "100%",
		position: "relative",
		[theme.breakpoints.down('sm')]: {
			flexGrow: "unset"
		},
	},
	logo: {
		width: 60,
		marginRight: 20,
		borderRadius: 99999,
		transition: "400ms",
		padding: 5,
		[hover]: {
			background: MAIN_COLOR
		},
		[theme.breakpoints.down('sm')]: {
			width: 50
		}
	},
	mobileTitle: {
		color: "white",
		flexGrow: 1,
		textTransform: "uppercase",
		textAlign: "center",
		fontWeight: 600
	},
	menuItem: {
		cursor: "pointer",
		color: "white",
		height: "100%",
		padding: "20px 15px",
		display: "inline",
		textAlign: "center",
		fontSize: "1.6rem",
		fontWeight: 600,
		transition: "200ms",
		[hover]: {
			color: `${MAIN_COLOR_VIBRANT} !important`
		},
		[active]: {
			color: `${MAIN_COLOR_VIBRANT} !important`
		},
		[theme.breakpoints.down('lg')]: {
			padding: "20px 10px",
		}
	},
	dropdown: {
		backgroundColor: "#1a1a1a",
		borderRadius: "0px",
		padding: 0,
	},
	dropdownItem: {
		color: "white",
		borderRadius: "0px",
		fontSize: "1.5rem",
		fontFamily: "unset",
		fontWeight: 600,
		textAlign: "center",
		transition: "200ms",
		padding: "10px 20px",
		[hover]: {
			color: MAIN_COLOR_VIBRANT
		},
	}
});

interface HeaderProps extends WithStyles<typeof styles> {
	lang: string,
	page: pageType,
	itemId?: number | string,
	transparent?: boolean,
	inverse?: boolean
}

type MenuItemSimple = {
	href: string, titleGreek: string, titleEnglish: string
};

type MenuItemDropdown = {
	href: string, titleGreek: string, titleEnglish: string, items: MenuItemSimple[]
}

const Header = withStyles(styles)(({ lang, page, itemId, classes, transparent = false, inverse = false }: HeaderProps) => {
	let appBarRef = React.createRef();

	/** Boolean value to see if  */
	const [isTop, setIsTop] = useState<boolean>(transparent);

	const items: (MenuItemSimple | MenuItemDropdown)[] = [
		{
			href: `${CATEGORY_PREFIX}/2/${lang}`,
			titleGreek: "ΣΥΝΑΥΛΙΕΣ",
			titleEnglish: "CONCERTS"
		},
		{
			href: `${CATEGORY_PREFIX}/75/${lang}`,
			titleGreek: "ΠΡΟΣΕΧΩΣ",
			titleEnglish: "COMING SOON"
		},
		{
			href: "",
			titleGreek: "Η ΟΡΧΗΣΤΡΑ",
			titleEnglish: "PROFILE",
			items: [
				{
					href: `${INNERPAGE_PREFIX}/197/${lang}`,
					titleGreek: "Η ΙΣΤΟΡΙΑ ΜΑΣ",
					titleEnglish: "OUR HISTORY"
				},
				// {
				// 	href: `${INNERPAGE_PREFIX}/14/${lang}`,
				// 	titleGreek: "Ο ΣΚΟΠΟΣ ΜΑΣ",
				// 	titleEnglish: "OUR GOAL"
				// },
				{
					href: `${INNERPAGE_PREFIX}/44/${lang}`,
					titleGreek: "ΠΡΟΕΔΡΟΣ",
					titleEnglish: "PRESIDENT"
				},
				{
					href: `${INNERPAGE_PREFIX}/45/${lang}`,
					titleGreek: "ΚΑΛΛΙΤΕΧΝΙΚΟΣ ΔΙΕΥΘΥΝΤΗΣ",
					titleEnglish: "ARTISTIC DIRECTOR"
				},
				{
					href: `${CATEGORY_PREFIX}/1/${lang}`,
					titleGreek: "ΟΙ ΜΟΥΣΙΚΟΙ",
					titleEnglish: "MUSICIANS"
				},
				{
					href: `${CATEGORY_PREFIX}/24/${lang}`,
					titleGreek: "ΔΙΟΙΚΗΣΗ",
					titleEnglish: "ADMINISTRATION"
				},
			]
		},
		{
			href: `${CATEGORY_PREFIX}/74/${lang}`,
			titleGreek: "ΔΙΣΚΟΓΡΑΦΙΑ",
			titleEnglish: "DISCOGRAPHY"
		},
		{
			href: "",
			titleGreek: "ΝΕΑ",
			titleEnglish: "NEWS",
			items: [
				{
					href: `${CATEGORY_PREFIX}/27/${lang}`,
					titleGreek: "ΝΕΑ",
					titleEnglish: "ΝΕWS"
				},
				{
					href: `${CATEGORY_PREFIX}/38/${lang}`,
					titleGreek: "BLOG",
					titleEnglish: "BLOG"
				},
				{
					href: `${CATEGORY_PREFIX}/37/${lang}`,
					titleGreek: "ΚΡΙΤΙΚΕΣ",
					titleEnglish: "PRESS"
				}
			]
		},
		{
			href: `${GALLERY_PREFIX}/${lang}`,
			titleGreek: "ΣΤΙΓΜΕΣ",
			titleEnglish: "MOMENTS"
		},
		// {
		//     href: `#`,
		//     titleGreek: "ΥΠΗΡΕΣΙΕΣ",
		//     titleEnglish: "SERVICES"
		// },
		{
			href: `${FRONTPAGE_PREFIX}/${lang}#tr-contact`,
			titleGreek: "ΕΠΙΚΟΙΝΩΝΙΑ",
			titleEnglish: "CONTACT US",
		}
	];

	useEffect(() => {
		const listener = (event: any) => {
			if (findZeroScroll()) setIsTop(transparent);
			else setIsTop(false);
		}

		window.addEventListener('scroll', listener);

		return () => { window.removeEventListener('scroll', listener) }

	}, [transparent])


	const theme: Theme = useTheme();
	const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<AppBar
			position="fixed"
			ref={appBarRef}
			className={classes.root}
			color={!isTop ? undefined : "transparent"}
			style={{ backgroundColor: !isTop ? black : undefined }}
			elevation={0}
		>
			<Toolbar className={classes.toolbar} style={{ backgroundColor: !isTop ? black : undefined }}>
				{/* Logo */}
				<Link to={`${FRONTPAGE_PREFIX}/${lang}`}>
					<Box>
						<img src={isTop && inverse ? DarkLogo : Logo} className={classes.logo} alt="logo" />
					</Box>
				</Link>

				{/* Flexgrow 1 for mobile */}
				{isSmall && <div className={classes.mobileTitle} style={{ color: inverse && isTop ? black : "white" }}>{defaultTitle(lang)}</div>}

				{/* Desktop Menu */}
				<div className={classes.menu}>
					{
						isSmall ?
							<MobileMenu lang={lang} items={items} page={page} itemId={itemId} inverse={inverse && isTop} />
							:
							items.map((item: any, i: number) => {
								if (!item.items) {
									const LinkComp = item.href.includes("#") ? HashLink : Link;

									return (
										<LinkComp to={item.href} key={`header-item-${i}`} style={{ height: HEADER_HEIGHT }}>
											<div className={classes.menuItem} style={{ color: inverse && isTop ? black : "white" }}>
												{lang === GREEK_URL ? item.titleGreek : item.titleEnglish}
											</div>
										</LinkComp>
									)
								}
								else {
									return (
										<DropdownItem key={`header-item-${i}`} lang={lang} item={item} inverse={inverse && isTop} />
									)
								}
							})
					}
				</div>

				{!isSmall && <SearchForm inverse={isTop} lang={lang}/>}

				{/* Feed */}
				{!isSmall && <Feed lang={lang} page={page} itemId={itemId} inverse={inverse && isTop} />}
			</Toolbar>
		</AppBar>
	)
});

const DropdownItem = withStyles(styles)(({ lang, item, classes, inverse = false }: { lang: string, item: MenuItemDropdown, inverse?: boolean } & WithStyles<typeof styles>) => {

	return (
		<>
			<Popup
				trigger={open => (
					<div className={classes.menuItem} style={{ color: inverse ? black : "white" }} >
						<span style={open ? { color: MAIN_COLOR_VIBRANT } : {}}>
							{lang === GREEK_URL ? item.titleGreek : item.titleEnglish}
						</span>
					</div>
				)}
				on="hover"
				position="bottom center"
				closeOnDocumentClick
				arrow={false}
				closeOnEscape
				repositionOnResize
			>
				<Fade in={true} timeout={400}>
					<Paper className={classes.dropdown}>
						{
							item.items.map((sub: MenuItemSimple, i) => {
								const LinkComp = item.href.includes("#") ? HashLink : Link;
								return (
									<LinkComp to={sub.href} key={`header-sub-${sub.titleEnglish}`}>
										<MenuItem className={classes.dropdownItem}>
											{lang === GREEK_URL ? sub.titleGreek : sub.titleEnglish}
										</MenuItem>
									</LinkComp>
								)
							})
						}
					</Paper>
				</Fade>
			</Popup>
		</>
	)
})


interface MobileMenuProps extends WithStyles<typeof styles> {
	lang: string,
	items: (MenuItemSimple | MenuItemDropdown)[],
	page: pageType,
	itemId?: number | string,
	inverse?: boolean
}

const MobileMenu = withStyles(styles)(({ lang, items, page, itemId, classes, inverse = false }: MobileMenuProps) => {
	const [anchorEl, setAnchorEl] = useState<any>(null);

	const onClick = (e: any) => {
		if (anchorEl !== e.currentTarget) setAnchorEl(e.currentTarget)
	}

	const onClose = () => { setAnchorEl(null); }

	return (
		<div>
			<IconButton edge="start" style={{ color: inverse ? black : "white" }} aria-label="menu" onClick={onClick}>
				<MenuIcon style={{ fontSize: "3rem" }} />
			</IconButton>

			<Menu
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={Boolean(anchorEl)}
				disableScrollLock
				onClose={onClose}
				MenuListProps={{ className: classes.dropdown }}
				elevation={0}
			>
				<MenuItem>
					<Feed lang={lang} page={page} itemId={itemId} />
				</MenuItem>

				<MenuItem>
					<SearchForm lang={lang}/>
				</MenuItem>
				{
					items.map((item: any, i) => {
						const LinkComp = item.href.includes("#") ? HashLink : Link;

						if (!item.items) {
							return (
								<LinkComp to={item.href} key={`header-${item.titleEnglish}`}>
									<MenuItem className={classes.dropdownItem}>
										{lang === GREEK_URL ? item.titleGreek : item.titleEnglish}
									</MenuItem>
								</LinkComp>
							)
						}
						else {
							return <MobileSubMenu key={`header-${item.titleEnglish}`} lang={lang} item={item} />
						}
					})
				}
			</Menu>
		</div>
	)
})

const MobileSubMenu = withStyles(styles)(({ lang, item, classes }: { lang: string, item: MenuItemDropdown } & WithStyles<typeof styles>) => {
	const [anchorEl, setAnchorEl] = useState<any>(null);

	const onClick = (e: any) => {
		if (anchorEl !== e.currentTarget)
			setAnchorEl(e.currentTarget)
	}

	const onClose = () => { setAnchorEl(null); }

	return (
		<>
			<MenuItem onClick={onClick} className={classes.dropdownItem}>
				{lang === GREEK_URL ? item.titleGreek : item.titleEnglish}
			</MenuItem>
			<Menu
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: "top", horizontal: "left" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				open={Boolean(anchorEl)}
				disableScrollLock
				onClose={onClose}
				MenuListProps={{ className: classes.dropdown }}
			>
				{
					item.items.map((sub: MenuItemSimple, i) => {
						const LinkComp = item.href.includes("#") ? HashLink : Link;
						return (
							<LinkComp to={sub.href} key={`header-sub-${sub.titleEnglish}`}>
								<MenuItem className={classes.dropdownItem}>
									{lang === GREEK_URL ? sub.titleGreek : sub.titleEnglish}
								</MenuItem>
							</LinkComp>
						)
					})
				}
			</Menu>
		</>
	)
});

export default Header;

