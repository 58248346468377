import React from 'react';
import { Topslide } from '../util/front_datatypes';
import { IMAGES_GENERAL_URL_PREFIX, LOGO_TRANSP, INNERPAGE_PREFIX, GREEK_URL, FRONTPAGE_PREFIX } from '../util/constants';
import { lang_suffix } from '../util/lang_util';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import "../style/Carousel.scss"
import LOGO_TRANSP_EN from "../data/images/Logo_Transp_EN.png"
import { decodeHTML } from '../util/util';

interface CarouselProps {
    slides: Topslide[],
    lang: string
}

const Carousel = ({ slides, lang }: CarouselProps) => {
    const langSuffix = lang_suffix(lang);
    const call2action = {
        "": "Περισσότερα",
        "_en": "more"
    }
    
    return (
        <div className="tr-home-slider" id="tr-home">
            <div id="home-carousel" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner" role="listbox">
                    {
                        slides.sort((a, b) => {
                            if (a.order < b.order) return -1;
                            if (a.order > b.order) return 1;
                            return 0;
                        }).map((slide, i) => {

                            let description = slide[`description${langSuffix}`];
                            description = description ? decodeHTML(description) : "";

                            return (
                                <div
                                    key={`carousel-slide${i}`}
                                    className={`item ${i === 0 ? "active" : ""}`}
                                    style={{ backgroundImage: `url(${IMAGES_GENERAL_URL_PREFIX}/${slide.image})` }}
                                >
                                    {/* <div className="overlay"></div> */}

                                    <div className="slide-content">
                                        <div className="tr-middle">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-sm-8">
                                                        <div className="home-content">

                                                            <div className="tr-logo" data-animation="animated fadeInDown">
                                                                <Link to={`${FRONTPAGE_PREFIX}/${lang}`}>
                                                                    <img className="img-responsive" src={lang === GREEK_URL ? LOGO_TRANSP : LOGO_TRANSP_EN} alt="Philharmonia Logo" />
                                                                </Link>
                                                            </div>

                                                            <span className="bg-color" data-animation="animated fadeInDown">{slide[`overtitle${langSuffix}`]}</span>
                                                            <h1 data-animation="animated fadeInLeft">{slide[`title${langSuffix}`]}</h1>
                                                            <h3><span data-animation="animated fadeInUp">{description}</span></h3>
                                                            <br/>
                                                            <Link to={`${INNERPAGE_PREFIX}/${slide.id}/${lang}`} className="btn btn-primary" data-animation="animated fadeInUp">
                                                                {slide[`call2action${langSuffix}`] ? slide[`call2action${langSuffix}`] : call2action[langSuffix]}
                                                                <i className="fa fa-chevron-right" aria-hidden="true"></i>
                                                            </Link>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <ol className="carousel-indicators">
                        {
                            slides.map((slide, i) => {
                                return <li key={`slide-ind${i}`}data-target="#home-carousel" data-slide-to={i} className={i === 0 ? "active" : ""}></li>;                                                })
                        }
                    </ol>
                </div>
                <HashLink className="carousel-control-prev" to="#home-carousel" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </HashLink>
                <HashLink className="carousel-control-next" to="#home-carousel" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </HashLink>
            </div>
        </div>
    )
}

export default Carousel;