import React from 'react'
import { Fade, Grid } from '@material-ui/core'
import "../style/Loading.scss";
import LoadingIcon from "../data/images/loading.gif"
import LoadingBg from "../data/images/loading-bg.jpg"

interface LoadingProps {
    loading: boolean
}

const Loading = ({loading}: LoadingProps) => {
    
    return (
        <Fade in={loading} timeout={{enter: 0, exit: 300}}>
            <div className="loading background">

                <img alt="apho_loading_bg" src={LoadingBg} className="background"/>

                <Grid container style={{height: "100%"}} alignItems="center">
                    <Grid item xs={12} style={{textAlign: "center"}}>
                        <img alt="apho_loading" src={LoadingIcon}/>
                    </Grid>
                </Grid>

            </div>
        </Fade>
    )
}

export default Loading;