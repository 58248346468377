import React, { useState, useEffect } from 'react';
import { lang_suffix, lang_url2num } from '../util/lang_util';
import Axios from 'axios';
import { CircularProgress, IconButton, Slide, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import Pattern from '../data/images/background_pattern_2.png'

import "../style/Newsletter.scss"
import { NEWSLETTER_ENDPOINT, MAIN_COLOR } from '../util/constants';

interface NewsletterProps {
    lang: string
}

const Newsletter = ({ lang }: NewsletterProps) => {
    const timeout = 2.5;

    const [email, setEmail] = useState<string>("");
    const [sent, setSent] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [hide, setHide] = useState<boolean>(true);
    const [color, setColor] = useState<string>("unset");

    const langSuffix = lang_suffix(lang);

    const success = {
        "": "Η εγγραφή σας ήταν επιτυχής!",
        "_en": "Your subscription was successful!"
    }

    const failure = {
        "": "Αποτυχία: Η εγγραφή σας δεν έγινε.",
        "_en": "Failure: Your subscription failed."
    }

    const unsuccess = {
        "": "Η απεγγραφή σας ήταν επιτυχής!",
        "_en": "Your unsubscription was successful!"
    }

    const unfailure = {
        "": "Αποτυχία: Η απεγγραφή σας δεν έγινε.",
        "_en": "Failure: Your unsubscription failed"
    }

    // First Set (Subscription)

    const header = {
        "": "Γραφτείτε για τις συναυλίες, τα νέα και τους διαγωνισμούς μας",
        "_en": "Subscribe to be notified about our concerts, news, and contests"
    }

    const description = {
        "": `Μέσα από τα email μας θα μπορείτε να κερδίζετε προσκλήσεις, να ενημερώνεστε για τις επερχόμενες συναυλίες μας και να λαμβάνετε πρώτοι τις προσφορές μας.
        Μηδενικό spamming. Απεγγραφή οποτεδήποτε.`,
        "_en": `Through our emails, you will be able to win tickets, get notifed about upcoming concerts, and be the first to receive our deals.
        Zero spamming. Unsubscribe whenever you want.`
    };


    const subscribeAction = {
        "": "Εγγραφή",
        "_en": "Subscribe",
    }

    const unsubscribeAction = {
        "": "Απεγγραφή",
        "_en": "Unsubscribe"
    }
 

    // Second Set (Concert)

    // const header = {
    //     "": "Όπερα «Μάρκος Μπότσαρης» του Ν. Μεταξά-Τζανή",
    //     "_en": "Opera “Marco Bozzari” by N. Metaxas-Tzanis"
    // }

    // const description = {
    //     "": `22 Οκτωβρίου 2021`,
    //     "_en": `October 22, 2021`
    // };


    // const subscribeAction = {
    //     "": "Δωρεάν δελτία εισόδου",
    //     "_en": "Book your free tickets",
    // }

    // const link = {
    //     "": "https://www.viva.gr/tickets/opera/festivalmarkos-botsaris/",
    //     "_en": "https://www.viva.gr/tickets/opera/festivalmarkos-botsaris/"
    // }


    // Methods

    const subscribe = () => {
        if (email === "") return;
        send("1", success[langSuffix], failure[langSuffix])
    }

    const unSubscribe = () => {
        if (email === "") return;
        send("2", unsuccess[langSuffix], unfailure[langSuffix])
    }

    const send = (type: string, successMessage: string, failureMessage: string) => {
        const url = `${NEWSLETTER_ENDPOINT}`;

        setSent(true);
        setLoading(true);

        let formData = new FormData();
        formData.append('email', email);
        formData.append('language', lang_url2num(lang));
        formData.append('type', type);


        Axios(url, {
            method: 'POST',
            data: formData
        })
            .then(res => {
                setLoading(false);
                setMessage(successMessage);
                setColor("initial");

                // Hide after success
                setTimeout(() => {
                    setHide(true)
                }, 2 * 1000)
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                setMessage(failureMessage);
                setColor("red");

                // Rerender buttons after failure
                setTimeout(() => {
                    setSent(false)
                }, 3 * 1000)
            })
    }

    // let x seconds pass
    // then show newsletter
    useEffect(() => {
        setTimeout(() => {
            setHide(false);
        }, timeout * 1000)
    }, [])


    return (
        <Slide in={!hide} direction="up">
            <Paper square elevation={10} className="popup image-bg" style={{backgroundImage: `url(${Pattern})`}}>
                {/* <div className="popup"> */}
                <IconButton className="closerpop" onClick={() => setHide(true)}>
                    <CloseIcon />
                </IconButton>

                <h4>{header[langSuffix]}</h4>
                <p>{description[langSuffix]}</p>

                {/* <a href={link[langSuffix]} target="_blank" rel='noopener noreferrer'>
                    <div className="btn btn-primary">{subscribeAction[langSuffix]}</div>
                </a> */}
                
                <input id="ctsubemail" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                {
                    loading ?
                        <CircularProgress style={{ color: MAIN_COLOR }} />
                        :
                        (
                            !sent ?
                                <>
                                    <div className="btn btn-primary" onClick={subscribe}>{subscribeAction[langSuffix]}</div>
                                    <div className="btn btn-primary" onClick={unSubscribe}>{unsubscribeAction[langSuffix]}</div>
                                </>
                                :
                                <h5 style={{ color: color }}>{message}</h5>
                        )

                }
                {/* </div> */}
            </Paper>
        </Slide>

    )
}

export default Newsletter;