import React, { useEffect, useState } from 'react';
import {New, Event, ComingSoon, Blog} from '../util/front_datatypes';
import { lang_suffix } from '../util/lang_util';
import { Link } from 'react-router-dom'
import "../style/About.scss";
import { INNERPAGE_PREFIX, dateFormat, GREEK_URL } from '../util/constants';
import Pattern from '../data/images/background_pattern_2.png';

interface AboutProps{
    lang: string,
    comingsoon: ComingSoon[],
    news: New[],
    blog: Blog[]
}

const About = ({lang, comingsoon, blog, news}: AboutProps) => {
    const langSuffix = lang_suffix(lang);
    
    // How many comingsoon and news we will show max
    const limit = 4;
    const leftUpLimit = 1;
    const leftDownLimit = 2;

    const [maxHeight, setMaxHeight] = useState<number | string>("100%");

    const call2action = {
        "": "Περισσότερα",
        "_en": "more"
    }

    const newsTitle = {
        "": "Νέα",
        "_en": "News"
    }

    const comingSoonTitle = {
        "": "Προσεχώς",
        "_en": "Coming Soon"
    }

    const blogTitle = {
        "": "Blog",
        "_en": "Blog"
    }

    // Set the height of the two sections equal to the tallest of the 2
    // Note: They have height based on their inner content, so whichever is tallest, should be the maxHeight
    useEffect( () => {
        const comingsoonHeight = document.getElementsByClassName("comingsoon")[0].clientHeight;
        const newsHeight = document.getElementsByClassName("new")[0].clientHeight;

        setMaxHeight(comingsoonHeight > newsHeight ? comingsoonHeight : newsHeight);
    }, [comingsoon, news])

    const AboutEvent = ({data, last}: {data: Event, last: boolean}) => {
        const eventfrom = data.eventfrom ? data.eventfrom : "";
        const innerLink = `${INNERPAGE_PREFIX}/${data.id}/${lang}`
        const description = data[`description${langSuffix}`];
        const dateLocale = lang === GREEK_URL ? 'el-GR' : 'en-US';

        return (
            <Link to={innerLink} style={{width: "100%", height: "100%"}}>
                <button className="about">
                    {new Date(eventfrom).getFullYear() > 2015 ? new Date(eventfrom).toLocaleDateString(dateLocale, dateFormat) : ""}
                    <h2>{data[`title${langSuffix}`]}</h2>
                    <div dangerouslySetInnerHTML={{__html: description !== null ? description : ""}}/>
                    <p className="btn btn-default">
                        {call2action[langSuffix]}
                        <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    </p>
                    {last ? "" : <hr/>}
                </button>
            </Link>
        )
    }

    return (
        <div 
            id="tr-about"
            className="tr-about image-bg"
            style={{backgroundImage: `url(${Pattern})`, backgroundRepeat: "repeat", backgroundSize: "unset"}}
        >
            {/* <div style={{backgroundColor: `#141f1cea`, position: "absolute", top: 0, left: 0, width: "100%", height: maxHeight}}/> */}

            <div className="overlay"></div>
            <div className="about-content">
                <div className="row" style={{position: "absolute", height: "100%", width: "100%", top: 0, left: 0, marginRight: 0, marginLeft: 0}}>
                    <div className="col-sm-6 right-contant comingsoon-bg" style={{minHeight: maxHeight}}></div>
                    <div className="col-sm-6 right-contant new-bg" style={{minHeight: maxHeight}}></div>
                </div>
            </div>
            <div className="about-content container">
                    <div className="row" style={{height: "100%", width: "100%"}}>
                        <div className="col-sm-6 right-contant section-padding comingsoon" style={{minHeight: maxHeight}}>
                            <div className="title">
                                <h1>{comingSoonTitle[langSuffix]}</h1>
                                {comingsoon.slice(0, limit).map( (data: ComingSoon, i: number) => {
                                    if (data.eventto && new Date(data.eventto) < new Date(Date.now()))
                                        return null

                                    return <AboutEvent key={`front-comingsoon-${i}`} data={data} last={i === limit - 1}/>
                                })}
                            </div>
                        </div>

                        <div className="col-sm-6 right-contant section-padding new" style={{minHeight: maxHeight}}>
                            <div className="title">
                                <h1>{newsTitle[langSuffix]}</h1>
                                {news.slice(0, leftUpLimit).map( (data: New, i: number) => <AboutEvent key={`front-new-${i}`} data={data} last={i === leftUpLimit - 1}/>)}
                            </div>

                            <div className="title">
                                <h1>{blogTitle[langSuffix]}</h1>
                                {blog.slice(0, leftDownLimit).map( (data: New, i: number) => <AboutEvent key={`front-new-${i}`} data={data} last={i === leftDownLimit - 1}/>)}
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default About;