import React, { useState, useEffect } from 'react';

import { withStyles, WithStyles, createStyles, Theme, TextField, InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from "@material-ui/icons/Search"
import { useHistory } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import "../style/SearchForm.scss"
import axios from 'axios';
import { lang_suffix } from '../util/lang_util';
import { GET_TAGS_ENDPOINT, GREEK_URL } from '../util/constants';

const styles = (theme: Theme) => createStyles({
	root: {
		marginRight: 20,
		maxWidth: 200,
		transition: "400ms",
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		backgroundColor: "#2d2d2d",
		border: "none !important",
		outline: "none !important"
	},
	rootInverse: {
		backgroundColor: "#eeeeee",
	},
	input: {
		fontSize: 15,
		maxWidth: 200,
		color: "white",
		paddingRight: 0
	},
	inputInverse: {
		fontSize: 15,
		color: "#1a1a1a"
	},
	icon: {
		transition: "500ms",
		color: "white"
	},
	iconInverse: {
		transition: "500ms",
		color: "#1a1a1a"
	},
	option: {
		fontSize: 16
	},
	chip: {
		fontSize: 14,
		marginRight: 3,
		backgroundColor: "#4a4a4a",
		color: "white"
	},
	chipInverse: {
		backgroundColor: "#dddddd",
		color: "unset"
	}
});

interface SearchFormProps extends WithStyles<typeof styles>
{
	inverse?: boolean,
	lang: string
}

export type TTag =
{
	id: number,
	text: string,
	created: Date,
	modified: Date,
	language: number
}


const SearchForm = withStyles(styles)(({ lang, inverse, classes }: SearchFormProps) =>
{
	const [selected, setSelected] = useState<string[]>([]);
	const [tags, setTags] = useState<string[]>([]);
	const [error, setError] = useState<boolean>(false);

	// GET all tags
	useEffect( () => {

		axios.get(GET_TAGS_ENDPOINT)
		.then(res => res.data)
		.then(data => {
			setTags(
				data[`tags${lang_suffix(lang)}`]
					.filter((a: TTag) => a && a.text.length > 0)
					.map((a: TTag) => {
						const tag = a.text.split(' ')
							.filter(w => w && w.length > 0)
							.map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
							.join(' ');
						return tag
					})
					.concat(
						data[`titles${lang_suffix(lang)}`]
							.filter((t: any) => t && t.title && t.title.length > 0)
							.map((t: any) => t.title)
					)
			);
		})
		.catch(err => console.error(err))
	}, [lang])

	const history = useHistory();
	const submit = (event: React.FormEvent<HTMLFormElement>) => {
		if (selected.length === 0)
		{
			event.preventDefault();
			setError(true);
			return;
		}

		setError(false);
		history.push(`/search/${encodeURIComponent(selected.join(','))}/${lang}`)
	}

	return (
		<form onSubmit={submit}>
			<Autocomplete
				id="autocomplete"
				options={tags}
				freeSolo
				value={selected}
				multiple
				onChange={
					(e, value) => {
						e.preventDefault();
						if (!value) return;

						setSelected(value);
					}
				}
				className={`${classes.root} ${inverse ? classes.rootInverse : ""}`}
				classes={{
					option: classes.option,
				}}
				ChipProps={{className: `${classes.chip} ${inverse ? classes.chipInverse : ""}`}}
				renderInput={(params) =>
					<TextField {...params}
						variant="outlined"
						className={`${classes.root} ${inverse ? classes.rootInverse : ""}`}
						size="small"
						placeholder={lang === GREEK_URL ? "Αναζήτηση" : "Search"}
						InputProps={{
							...params.InputProps,
							error: error,
							fullWidth: false,
							className: `${classes.input} ${inverse ? classes.inputInverse : ""}`,
							notched: false,
							endAdornment: (
								<InputAdornment position="end">
									<IconButton type="submit">
										<SearchIcon className={inverse ? classes.iconInverse : classes.icon}/>
									</IconButton>
								</InputAdornment>
							)
						}}
						inputProps={{
							...params.inputProps,
						}}
					/>
				}
			/>
		</form>
	)
});

export default SearchForm;
