import React from 'react';
import { Major } from '../util/front_datatypes';
import { IMAGES_GENERAL_URL_PREFIX, INNERPAGE_PREFIX, GREEK_URL } from '../util/constants';
import { lang_suffix } from '../util/lang_util';
import "../style/Portfolio.scss";
import { Link } from 'react-router-dom';
import { hasPassed } from '../util/util';

interface PortfolioProps
{
    majors: Major[],
    lang: string
}

const Portfolio = ({majors, lang}: PortfolioProps) => {

    const langSuffix = lang_suffix(lang);

    const call2action_def = {
        "": "Περισσότερα",
        "_en": "more"
    }

    return (
        <div id="tr-portfolio" className="tr-portfolio">
            
                <div className="portfolio-slider" >
                    {
                        majors.map( (major, i) => {
                            const innerLink = `${INNERPAGE_PREFIX}/${major.id}/${lang}`;

                            // To get over dangerousHTML problem
                            const description = major[`description${langSuffix}`];
                            const call2action = major[`call2action${langSuffix}`] !== null ? major[`call2action${langSuffix}`] : call2action_def[langSuffix];

                            return (
                                <Link to={innerLink} key={`major-${i}`}>
                                    <div className="portfolio-item">
                                    {
                                        major.eventfrom !== null ? (
                                            !hasPassed(major.eventfrom) ?
                                            <img alt="coming soon" className="prosexos" src={`https://erp.maliaras.gr/images/apho/prosexos${lang === GREEK_URL ? "_GR" : "_EN"}.png`}/> : ""
                                        ) : ""
                                    }
                                        <img className="img-responsive" alt="portfolio-item" src={`${IMAGES_GENERAL_URL_PREFIX}/${major.image}`}/>
                                        <div className="portfolio-overlay">
                                            <div className="portfolio-info">
                                                <h2>
                                                        {major[`title${langSuffix}`]}
                                                </h2>
                                                <p className="inner-a" dangerouslySetInnerHTML={{__html: description !== null ? description: ""}}/>
                                                <p className="btn btn-default">
                                                    {call2action}
                                                    <i className="fa fa-chevron-right" aria-hidden="true"/>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>
        </div>
    )
}

export default Portfolio;