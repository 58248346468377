import React from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { CATEGORY_PREFIX } from '../util/constants';
import '../style/Breadcrumb.scss'

const styles = (theme: Theme) => createStyles({

});

interface BreadcrumbProps extends WithStyles<typeof styles>, React.HTMLAttributes<HTMLDivElement>
{
	innerTitle: string | null,
	categoryTitle: string | null,
	categoryId: string | number,
	lang: string
}


const Breadcrumb = withStyles(styles)(({ lang, classes, innerTitle, categoryTitle, categoryId, ...props }: BreadcrumbProps) =>
{
	if (innerTitle === null || categoryTitle === null) return null;

	return (
		<div className='Breadcrumb' {...props}>
			<Link to={`${CATEGORY_PREFIX}/${categoryId}/${lang}`} className='category-link'>
				{categoryTitle}
			</Link>
			{" "}&nbsp;<i className="fas fa-angle-right"/> &nbsp;<span>{innerTitle}</span>
		</div>
	)
});

export default Breadcrumb;

