import { useLocation } from "react-router";

export const hasPassed = (date: Date | string) => {
    return new Date(date) < new Date(Date.now());
}

export const decodeHTML = (s: string) => {
    let txt = document.createElement("textarea");
    txt.innerHTML = s.replace(/<[^>]*>/g, '');
    return txt.value
}

export enum VideoSource
{
    Facebook,
    Youtube,
    Vimeo,
    Twitch
};

export const extractVideoSource = (source: string) =>
{
    switch (source)
    {
        case "Youtube":
            return VideoSource.Youtube;
        case "Facebook":
            return VideoSource.Facebook;
        case "Vimeo":
            return VideoSource.Vimeo;
        case "Twitch":
            return VideoSource.Twitch;
        default:
            return VideoSource.Youtube;
    }
}

export const videoLink = (videoid: string, source: VideoSource) =>
{
    switch (source)
    {
        case VideoSource.Facebook:
            return `https://fb.watch/${videoid}`;
        case VideoSource.Youtube:
            return `https://www.youtube.com/watch?v=${videoid}`;
        case VideoSource.Vimeo:
            return `https://vimeo.com/${videoid}`;
        default:
            return `https://www.youtube.com/watch?v=${videoid}`;
    }
}

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}