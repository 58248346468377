import React from 'react'
import { RouteComponentProps } from 'react-router';
import useTitle, { defaultTitle } from '../util/useTitle';
import Footer from './Footer';
import "../style/NotFound.scss"
import { lang_suffix } from '../util/lang_util';
import { pageType } from './Feed';
import { Link } from 'react-router-dom';
import Header from './Header';
import { FRONTPAGE_PREFIX, GREEK_URL, ENGLISH_URL } from '../util/constants';

interface NotFoundProps {
}

interface NotFoundParameters {
    lang: string
}

const NotFound = ({match}: NotFoundProps & RouteComponentProps<NotFoundParameters>) => {

    let lang = match.params.lang ? match.params.lang : GREEK_URL;
    lang = (lang !== GREEK_URL && lang !== ENGLISH_URL) ? GREEK_URL : lang;
    console.log(lang)

    useTitle(`404 | ${defaultTitle(lang)}`);

    const langSuffix = lang_suffix(lang);
    const notFound = {
        "": "Η Σελίδα δεν βρέθηκε",
        "_en": "Page not found"
    }

    const returnText = { 
        "": "Επιστροφή στην αρχική σελίδα",
        "_en": "Return to Home Page"
    }

    return (
        <div className="notfound-page">
            <Header lang={lang} page={pageType.Frontpage}/>

            <div className="tr-main-wrapper">
                <h1 style={{textAlign: "center", color: "white"}}>404</h1>
                <h1>{notFound[langSuffix]}</h1>
                <h3><Link to={`${FRONTPAGE_PREFIX}/${lang}`}>{returnText[langSuffix]}</Link></h3>
            </div>

            <Footer lang={lang} />
        </div>
    )
}

export default NotFound;