import React from 'react'
import { Helmet } from 'react-helmet'
import { defaultTitle, defaultDescription, defaultImage } from '../util/useTitle'
import { GREEK_URL } from '../util/constants'

interface MetaProps {
    lang: string,
    title?: string,
    description?: string,
    previewImage?: string,
    previewURL?: string
}

const Meta = ({ lang, title, description, previewImage, previewURL }: MetaProps) => {

    return (
        <Helmet>
            <html lang={lang === GREEK_URL ? "el" : "en"}/>
            <meta charSet="utf-8" />
            <title lang={lang === GREEK_URL ? "el" : "en"}>{title ? title : defaultTitle(lang)}</title>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="description" content={description ? description : defaultDescription(lang)} />
            <meta property="og:title" content={title ? title : defaultTitle(lang)} />
            <meta property="og:url" content={previewURL ? previewURL : "https://apho.gr"} />
            <meta property="og:image" content={previewImage ? previewImage : defaultImage} />
        </Helmet>
    )
}

export default Meta;