import React, { useState, useEffect } from 'react';

import { withStyles, WithStyles, createStyles, Theme, Fade, CircularProgress, Typography } from '@material-ui/core';
import { GREEK_URL, MAIN_COLOR, SEARCH_ENDPOINT } from '../util/constants';
import { pageType } from './Feed';
import Header from './Header';
import { RouteComponentProps } from 'react-router';
import Footer from './Footer';
import search_test_file from '../data/search_test_clear.json';
import { lang_suffix } from '../util/lang_util';
import Axios from 'axios';
import { WEBSITE_ID, INNERPAGE_PREFIX, IMAGES_GENERAL_URL_PREFIX, dateFormat, timeFormat } from "../util/constants";
import Meta from './Meta';
import { defaultTitle } from '../util/useTitle';
import { Link } from 'react-router-dom';
import { Event } from '../util/front_datatypes';
import "../style/Category.scss"
import { hover } from '../style/Theme';
import { SearchEvent } from '../util/search_datatypes';

const styles = (theme: Theme) => createStyles({
	title: {
		marginBottom: 30
	},
	entry: {
		padding: 20,
		margin: "0 auto"
	},
	button: {
		color: "black !important",
		[hover]: {
			color: `${MAIN_COLOR} !important`
		}
	}
});

interface SearchPageProps extends WithStyles<typeof styles> {

}

interface SearchParameters {
	query: string,
	lang: string
}


const SearchPage = withStyles(styles)(({ match, classes }: SearchPageProps & RouteComponentProps<SearchParameters>) => {
	const lang = match.params.lang ? match.params.lang : GREEK_URL;
	const query = match.params.query ? decodeURIComponent(match.params.query) : "";
	
	const [results, setResults] = useState<SearchEvent[]>(search_test_file.searchresults);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		const url = `${SEARCH_ENDPOINT}/${WEBSITE_ID}/1`;

		let formData = new FormData();
		formData.append('tags', query);

        Axios(url, {
            method: 'POST',
            data: formData
        })
		.then(res => {
			const r: SearchEvent[] = res.data.searchresults;
			setResults(r.sort((a, b) => +a.score < +b.score ? 1 : -1));
			setLoading(false);
		});

	}, [lang, query])

	const langSuffix = lang_suffix(lang);

	const title = {
		"": `Αναζήτηση: "${query.split(',').filter(s => s.trim() !== "").join('", "')}"`,
		"_en": `Search: "${query}"`
	};

	const call2action = {
        "": "Περισσότερα",
        "_en": "more"
	};

	const noResults = {
		"": "Δεν βρέθηκαν αποτελέσματα",
		"_en": "No results"
	};

	const tresults = {
		"": "αποτελέσματα",
		"_en": "results"
	};

	const dateLocale = lang === GREEK_URL ? 'el-GR' : 'en-US';


	return (
		<div className="apho-page category-page">
			<Header lang={lang} page={pageType.Search} itemId={query} transparent inverse/>

			<Meta
				lang={lang}
				title={title[langSuffix] + " | " + defaultTitle(lang)}
			/>

			<div className="tr-main-wrapper">
				<div className={`${classes.title} container`}>
					<div className="row">
						<h2 style={{textAlign: "center"}}>{title[langSuffix]}</h2>
					</div>
				</div>
					
					<div className="container">
						<div className="row">
							{!loading ? 
								<>
									<div className={`${classes.title} container`}>
										<div className="row">
											<h3 style={{textAlign: "center"}}>
												{!results || results.length === 0 ?
													noResults[langSuffix] : `${results.length} ${tresults[langSuffix]}`
												}
											</h3>
										</div>
									</div>
									{
										results.map((art: Event, i) => {
											const description = art[`description${langSuffix}`];
											const innerLink = `${INNERPAGE_PREFIX}/${art.id}/${lang}`;

											return (
												<Fade in={true} key={`article-${i}`}>
													<Link to={innerLink} >
														<div className={`${classes.entry} tr-post row`}>
															<div className="col-md-5">
																<div className="entry-header" style={{width: "50%"}}>
																	{art.image ?
																		<div className="entry-thumbnail">
																			<img className="img-responsive" src={`${IMAGES_GENERAL_URL_PREFIX}/${art.image}`}
																				alt={`${art[`title${langSuffix}`]} article`} />
																		</div> : null}
																</div>
															</div>

															<div className="col-md-7">
																<div className="post-content">
																	<div className="entry-meta">
																		{art.eventfrom ?
																			<span className="time">
																				{new Date(art.eventfrom).toLocaleDateString(dateLocale, dateFormat) + ", " + new Date(art.eventfrom).toLocaleTimeString(dateLocale, timeFormat)}
																			</span>
																			: ""}
																	</div>

																	<div className="entry-title">
																		{art[`overtitle${langSuffix}`]}
																		<h2>
																			{art[`title${langSuffix}`]}
																		</h2>
																	</div>

																	<div dangerouslySetInnerHTML={{ __html: description !== null ? description : "" }} />
																	<p className={`${classes.button} btn btn-default`}>
																		{call2action[langSuffix]}
																		<i className="fas fa-chevron-right" />
																	</p>
																</div>
															</div>
														</div>
														<hr/>
													</Link>
												</Fade>
											)
										})
									}
								</>
							: 
								<Typography component="div" align="center">
									<CircularProgress style={{color: MAIN_COLOR}}/>
								</Typography>
							}
						</div>
					</div>

			</div>


			<Footer lang={lang} />
		</div>
	)
});

export default SearchPage;

