import React, { useEffect, useState, useRef } from "react";
import { RouteComponentProps } from 'react-router';
import { pageType } from "./Feed";
import Carousel from "./Carousel";
import axios from 'axios';
import { Frontdata, Enabout } from "../util/front_datatypes";

import { lang_suffix, lang_url2num } from "../util/lang_util";

import "../style/Home.css"
import Portfolio from "./Portfolio";
import About from "./About";
import Calevents from "./Calevents";
import Contact from "./Contact";
import Footer from "./Footer";
import { portfolioSlider, teamSlider, slideHeight, isInViewport, scrollToSelector } from "../util/ext";
import { Link } from "react-router-dom";
import Meta from "./Meta";
import Newsletter from "./Newsletter";
import { defaultTitle } from "../util/useTitle";
import Loading from "./Loading";
import { FRONT_ENDPOINT, WEBSITE_ID, GREEK_URL } from "../util/constants";
import Header from "./Header";
import { Grow } from "@material-ui/core";
import Pattern from '../data/images/background_pattern_2.png';

interface HomeProps {

}

interface HomeParameters {
    lang: string
}

const Home = ({ location, match }: HomeProps & RouteComponentProps<HomeParameters>) => {
    const lang = match.params.lang ? match.params.lang : GREEK_URL;

    const [frontdata, setFrontdata] = useState<Frontdata>({ comingsoon: [], blog: [], calevents: [], categories: [], enabout: {}, majors: [], news: [], topslides: [], websiteid: +WEBSITE_ID });
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        const url = `${FRONT_ENDPOINT}/${WEBSITE_ID}/${lang_url2num(lang)}`;

        axios.get(url)
            .then(res => res.data)
            .then(res => {
                console.log(res.comingsoon)
                setFrontdata(res);
                portfolioSlider();
                teamSlider();
                slideHeight();

                setLoading(false);
            })

    }, [lang])

    useEffect(() => {
        if (location.hash.includes("#tr-contact")){
            console.log("hi")
            scrollToSelector("#tr-contact");
        }
        else
            window.scrollTo(0, 0);
    }, [frontdata, loading, location])

    return (
        <div className="tr-homepage homepage-1">
            <Loading loading={loading} />
            <h1 style={{ display: "none" }}>{defaultTitle(lang)}</h1>
            <Meta lang={lang} />

            <Header lang={lang} page={pageType.Frontpage} transparent />

            <Newsletter lang={lang} />

            <div className="tr-main-wrapper">
                <Carousel slides={frontdata.topslides} lang={lang} />
                <Goal lang={lang} enabout={frontdata.enabout} />
                <Portfolio lang={lang} majors={frontdata.majors} />
                <About lang={lang} comingsoon={frontdata.comingsoon} news={frontdata.news} blog={frontdata.blog}/>
                <Calevents lang={lang} calevents={frontdata.calevents} />
                <Contact lang={lang} />
            </div>

            <Footer lang={lang} />
        </div>
    )
}

const Goal = ({ lang, enabout }: { lang: string, enabout: Enabout }) => {
    const [visible, setVisible] = useState<boolean>(false);

    let element: any = useRef<any>();

    const langSuffix = lang_suffix(lang);
    const id = 14;
    const data = enabout[id];

    const call2action = {
        "": "Περισσότερα",
        "_en": "more"
    }

    // To get over dangerous html
    const description = data ? data[`description${langSuffix}`] : "";
    const title = data ? data[`title${langSuffix}`] : "";

    useEffect(() => {
        const listener = () => {
            if (isInViewport(element.current)) setVisible(true);
        }

        window.addEventListener('scroll', listener)

        return () => {window.removeEventListener('scroll', listener)}
    }, [element])

    return (

        <div
            className={`motor goal image-bg`}
            ref={element}
            style={{backgroundImage: `url(${Pattern})`, backgroundRepeat: "repeat", backgroundSize: "unset"}}
        >

            <Grow in={visible} timeout={2000}>
                <div>
                    <div className="title">
                        <h1>{title}</h1>
                    </div>
                    <div className="section-info" dangerouslySetInnerHTML={{ __html: description !== null ? description : "" }} />
                    <Link to={`/website/innerpage/${id}/2/${lang}`} className="btn btn-default">
                        {
                            call2action[langSuffix]
                        }
                        <i className="fa fa-chevron-right" aria-hidden="true" />
                    </Link>
                </div>
            </Grow>
        </div>

    )
}

export default Home;