import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

export const active = "&:active";
export const hover = "&:hover";

const themeBase = {
    typography: {
        fontFamily: 'Literata, serif'
    },
    overrides: {
        MuiPaper: {
            root: {
                color: undefined
            },
            rounded: {
                borderRadius: 0
            }
        }
    },

    
}

const Theme = responsiveFontSizes(createMuiTheme(themeBase));

export default Theme;