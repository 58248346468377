import { GREEK, GREEK_URL, ENGLISH_URL, ENGLISH } from "./constants"

export const lang_suffix = (lang: string) => {
    return lang === GREEK_URL ? "" : "_en";
}

export const lang_num2url = (lang: string) => {
    return lang === GREEK ? GREEK_URL : ENGLISH_URL;
}

export const lang_url2num = (lang: string) => {
    return lang === GREEK_URL ? GREEK : ENGLISH;
}