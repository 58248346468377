import React from 'react';
import { Switch, Route, withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import Home from './Home';
import Category from './Category';
import Innerpage from './Innerpage';
import Gallery from './Gallery';
import NotFound from './NotFound';
import { CATEGORY_PREFIX, GALLERY_PREFIX, INNERPAGE_PREFIX, FRONTPAGE_PREFIX, GREEK_URL, SEARCH_PREFIX } from '../util/constants';
import theme from "../style/Theme";
import { ThemeProvider } from '@material-ui/core';
import SearchPage from './SearchPage';

const App: React.FC = () => {

    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <Switch>
                    {/* Home */}
                    <Route exact path="/"><Redirect to={`${FRONTPAGE_PREFIX}/${GREEK_URL}`}/></Route>
                    <Route path={`${FRONTPAGE_PREFIX}/:lang`} component={ (routeProps: RouteComponentProps<any>) => <Home {...routeProps}/> }/>

                    {/* Category */}
                    <Route exact path={`${CATEGORY_PREFIX}/:id`}
                        render={({ match }) => {
                            return <Redirect to={`${CATEGORY_PREFIX}/${match.params.id}/${GREEK_URL}`}/>
                        }}
                    />
                    <Route path={`${CATEGORY_PREFIX}/:id/:lang`} component={ (routeProps: RouteComponentProps<any>) => <Category {...routeProps}/>}/>

                    {/* Innerpage */}
                    <Route exact path={`${INNERPAGE_PREFIX}/:id`}
                        render={({ match }) => {
                            return <Redirect to={`${INNERPAGE_PREFIX}/${match.params.id}/${GREEK_URL}`}/>
                        }}
                    />
                    <Route path={`${INNERPAGE_PREFIX}/:id/:lang`} component={ (routeProps: RouteComponentProps<any>) => <Innerpage {...routeProps}/>}/>

                    {/* Gallery */}
                    <Route exact path={`${GALLERY_PREFIX}`}
                        render={() => {
                            return <Redirect to={`${GALLERY_PREFIX}/${GREEK_URL}`}/>
                        }}
                    />
                    <Route path={`${GALLERY_PREFIX}/:lang`} component={ (routeProps: RouteComponentProps<any>) => <Gallery {...routeProps}/>}/>

                    {/* Search */}
                    <Route exact path={`${SEARCH_PREFIX}/:query`}
                        render={({ match }) => {
                            return <Redirect to={`${SEARCH_PREFIX}/${match.params.query}/${GREEK_URL}`}/>
                        }}
                    />
                    <Route exact path={`${SEARCH_PREFIX}/:query/:lang`} component={ (routeProps: RouteComponentProps<any>) => <SearchPage {...routeProps}/>}/>

                    <Route path={`*/:lang`} component={ (routeProps: RouteComponentProps<any>) => <NotFound {...routeProps}/>}/>
                    <Route component={ (routeProps: RouteComponentProps<any>) => <NotFound {...routeProps}/>}/>
                </Switch>
            </div>
        </ThemeProvider>
    )
}

export default withRouter(App);
