import React, { useState } from 'react';
import { lang_suffix } from '../util/lang_util';
import { CONTACT_ENDPOINT, GREEK_URL, MAIN_COLOR } from '../util/constants';
import Axios from 'axios';
import { CircularProgress } from '@material-ui/core';
import "../style/Contact.scss"

interface ContactProps {
    lang: string,
}

type fieldName = "ctname" | "ctemail" | "ctmobile" | "ctsubject" | "cttext";

type stateType = {
    [key: string]: string,
    ctname: string;
    ctemail: string;
    ctmobile: string;
    ctsubject: string;
    cttext: string;
};

const Field = ({ size, id, type, placeholder, state, setState }: { 
    size: string,
    id: fieldName,
    type: string,
    placeholder: string,
    state: stateType,
    setState: React.Dispatch<React.SetStateAction<stateType>>

}) => {

    return (
        <div className={size} key={`${id}-contactField`}>
            <div className="form-group">
                <input
                    id={id}
                    type={type}
                    className="form-control"
                    required
                    placeholder={placeholder}
                    name={id}
                    value={state[id]}
                    onChange={(e) => {const prev = state; setState({...prev, [id]: e.target.value});}}
                />
            </div>
        </div>
    );
}

const Contact = ({ lang }: ContactProps) => {
    const langSuffix = lang_suffix(lang);
    const contact = {
        "": "Επικοινωνία",
        "_en": "Contact Us"
    }

    const success = {
        "": "Το μήνυμα σας στάλθηκε με επιτυχία!",
        "_en": "Your message has been successfully sent!"
    }

    const failure = {
        "": "Αποτυχία: Το μήνυμα σας δεν στάλθηκε!",
        "_en": "Failure: Your message has not been sent!"
    }

    const emptyState = {ctname: "", ctemail: "", ctmobile: "", ctsubject: "", cttext: ""};
    const [state, setState] = useState<stateType>(emptyState);

    const [sent, setSent] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [statusMessage, setStatusMessage] = useState<string>("");
    const [color, setColor] = useState<string>("");

    const onSubmit = (event: any) => {
        event.preventDefault();

        const url = `${CONTACT_ENDPOINT}`;
        setSent(true);
        setLoading(true);
        
        let formData = new FormData();
        for (let property in state)
        {
            if (state.hasOwnProperty(property))
            {
                formData.append(property, state[property])
            }
        }

        Axios(url, {
            method: 'POST',
            data: formData
        })
            .then(res => {
                setLoading(false);
                setStatusMessage(success[langSuffix]);
                setColor("unset");
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                setStatusMessage(failure[langSuffix]);
                setColor("crimson");
            })

        setState(emptyState);
    }

    return (
        <div id="tr-contact" className="tr-contact-section image-bg section-padding">
            <div id="fcntm" />
            <div className="overlay" />
            <div className="container text-center">
                <div className="section-title">
                    <h1>{contact[langSuffix]}</h1>
                </div>

                <form className="contact-form" name="contact-form" onSubmit={onSubmit}>
                    <div className="row">
                        <Field size="col-sm-6" id="ctname" type="text" placeholder={lang === GREEK_URL ? "Όνομα" : "Name"} state={state} setState={setState}/>
                        <Field size="col-sm-6" id="ctemail" type="email" placeholder="Email" state={state} setState={setState}/>
                        <Field size="col-sm-6" id="ctmobile" type="number" placeholder={lang === GREEK_URL ? "Κινητό" : "Mobile"} state={state} setState={setState}/>
                        <Field size="col-sm-6" id="ctsubject" type="text" placeholder={lang === GREEK_URL ? "Θέμα" : "Subject"} state={state} setState={setState}/>

                        <div className="col-sm-12">
                            <div className="form-group">
                                <textarea 
                                    id="cttext" name="message" required className="form-control" rows={7}
                                    placeholder={lang === GREEK_URL ? "Το μήνυμα σας" : "Enter your text"}
                                    onChange={(e) => {const prev = state; setState({...prev, cttext: e.currentTarget.value})}}
                                    value={state.cttext}
                                />
                                <input type="hidden" className="newinfo" title="WEBTOKEN" id="language" name="language" value={lang} />
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        {
                            loading ?
                            <CircularProgress style={{color: MAIN_COLOR}}/>
                            :
                            <button id="ctsubmit" type="submit" className="btn btn-primary">
                                {lang === GREEK_URL ? "Αποστολή" : "Send"}
                                <i className="fa fa-chevron-right" aria-hidden="true" />
                            </button>
                        }
                        {
                            sent && !loading ?
                            <h2 style={{color: color}}>{statusMessage}</h2> : null
                        }
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Contact;