import React from 'react';
import { INNERPAGE_PREFIX, CATEGORY_PREFIX, FRONTPAGE_PREFIX, GALLERY_PREFIX, GREEK_URL, ENGLISH_URL, SEARCH_PREFIX } from '../util/constants';
import "../style/Feed.scss";
import { Link } from 'react-router-dom';

interface FeedProps {
    lang: string,
    page: pageType,
    itemId?: string | number,
    inverse? :boolean
}

export enum pageType {
    Frontpage,
    Innerpage,
    Category,
    Gallery,
    Search
}

export type SocialLink = { target: string, href: string, className: string, name: string }
export const links: SocialLink[] = [
    {
        target: "_blank",
        href: "https://open.spotify.com/artist/0gGmjio2Tcb4R8UttIQimZ?si=1EjiSt9KRK2rDiIYA0mPAw&dl_branch=1&nd=1",
        className: "fab fa-spotify",
        name: "Spotify"
    },
    {
        target: "_blank",
        href: "https://www.facebook.com/ath.philharmonia",
        className: "fab fa-facebook",
        name: "Facebook"
    },
    {
        target: "_blank",
        href: "http://twitter.com/athphilharmonia",
        className: "fab fa-twitter",
        name: "Twitter"
    },
    {
        target: "_blank",
        href: "https://www.youtube.com/channel/UC9p_Bp106n8bWM9shRmDiEw/videos",
        className: "fab fa-youtube",
        name: "Youtube"
    },
    {
        target: "_blank",
        href: "https://www.linkedin.com/company/athens-philharmonia-orchestra/",
        className: "fab fa-linkedin",
        name: "LinkedIn"
    },
    {
        target: "_blank",
        href: "https://www.instagram.com/ath.philharmonia/",
        className: "fab fa-instagram",
        name: "Instagram"
    },
]

const Feed = ({ lang, page, itemId, inverse = false }: FeedProps) => {

    const extractLanguageLink = (reverse?: boolean) => {
        const language = lang === GREEK_URL ? (reverse ? ENGLISH_URL : GREEK_URL) : (reverse ? GREEK_URL : ENGLISH_URL);

        let ret = "";
        switch (page) {
            case pageType.Frontpage:
                ret += `${FRONTPAGE_PREFIX}/${language}`;
                break;
            case pageType.Innerpage:
                ret += `${INNERPAGE_PREFIX}/${itemId}/${language}`;
                break;
            case pageType.Category:
                ret += `${CATEGORY_PREFIX}/${itemId}/${language}`;
                break;
            case pageType.Gallery:
                ret += `${GALLERY_PREFIX}/${language}`;
                break;
            case pageType.Search:
                ret += `${SEARCH_PREFIX}/${itemId}/${language}`;
                break;
            default:
                ret += `${FRONTPAGE_PREFIX}/${language}`;
                break;
        }

        return ret;
    }

    // const theme: Theme = useTheme();
    // const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div className="tr-feed">
            <ul className="list-inline" style={{ display: "inline" }}>
                {/* {!isSmall && <li className="list-inline-item" >{lang === GREEK_URL ? "ΒΡΕΙΤΕ ΜΑΣ" : "FOLLOW US"}</li>} */}
                {
                    links.slice(0, 4).map((link: SocialLink, i) => {
                        return (
                            <li key={`feed${i}`} className="list-inline-item" >
                                <a target={link.target} href={link.href}>
                                    <i className={link.className + " feed-icon" } style={{
                                        color: inverse ? "black" : "white"
                                    }}/>
                                </a>
                            </li>
                        )
                    })
                }
                <li className="list-inline-item" style={{color: inverse ? "black" : "white"}}>|</li>
                <li className="list-inline-item">
                    <Link to={extractLanguageLink(true)}>
                        <img className="feed-flag" alt="language" src={`https://erp.maliaras.gr/images/${lang === ENGLISH_URL ? "greece" : "united-kingdom"}-flag-icon-32.png`} />
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default Feed;