import { useEffect } from "react"
import { GREEK_URL } from "./constants";

const useTitle = (title: string, dep?: any) => {
    useEffect( () => {
        document.title = title;
    }, [title, dep])
}

/** A generic title */
export const defaultTitle = (lang: string) => lang === GREEK_URL ? "Φιλαρμόνια Ορχήστρα Αθηνών" : "Athens Philharmonia Orchestra";

/** A generic description */
export const defaultDescription = (lang: string) => lang === GREEK_URL ? 
    "Φιλαρμόνια Ορχήστρα Αθηνών. Ορχήστρα αφιερωμένη στη νεοελληνική έντεχνη µουσική"
    :
    "Athens Philharmonia Orchestra. An Orchestra dedicated to the modern Greek art music";

export const defaultImage = "https://erp.maliaras.gr/images/content/da195e10d53f2fad63772b7da3908175.jpg"

export default useTitle;